import styled from "styled-components";
import { rem } from "polished";

// icons

const Close = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="15.6772"
      y="14.3932"
      width="1.5"
      height="19.6479"
      transform="rotate(135 15.6772 14.3932)"
      fill="currentColor"
    />
    <rect
      x="1.78418"
      y="15.3405"
      width="1.5"
      height="19.6479"
      transform="rotate(-135 1.78418 15.3405)"
      fill="currentColor"
    />
  </svg>
);

const Min = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="13.3999"
      y="6.57336"
      width="1.5"
      height="12.8"
      transform="rotate(90 13.3999 6.57336)"
      fill="currentColor"
    />
  </svg>
);

const Plus = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6.57312"
      y="0.599976"
      width="1.5"
      height="12.8"
      fill="currentColor"
    />
    <rect
      x="13.3999"
      y="6.57336"
      width="1.5"
      height="12.8"
      transform="rotate(90 13.3999 6.57336)"
      fill="currentColor"
    />
  </svg>
);

const StyledButton = styled.button`
  ${({ theme, $open }) => `
    background-color: transparent;
    color: ${theme.color.key.default};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${rem(40)};
    height: ${rem(40)};
    border: 0;
    margin-left: auto;
    transform: ${$open ? `rotate(45deg)` : `rotate(0deg)`};
    &:hover {
        color: ${theme.color.key.dark};
        cursor: pointer;
    }
  `}
`;

const Icon = ({ id }) => {
  switch (id) {
    case "close":
      return <Close />;
    case "min":
      return <Min />;
    case "plus":
      return <Plus />;

    default:
      return null;
  }
};

const TransparentButton = ({ id, className, onClick, open }) => {
  return (
    <StyledButton className={className} onClick={onClick} $open={open}>
      <Icon id={id} />
    </StyledButton>
  );
};

export default TransparentButton;
