import styled from "styled-components";
import { rem } from "polished";
import LogoMcc from "./LogoMcc";

export const StyledPageHeader = styled.header`
  ${({ theme }) =>
    `
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 0;
  padding: ${rem(16)} 0;
  border-bottom: ${rem(1)} solid ${theme.color.globalMenu.dark};
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  position: sticky;
  background-color: ${theme.color.key.light};
  z-index: 1;
  padding-left: ${rem(24)};

  @media only screen and (min-width: ${theme.breakPoint}px) {
    padding-top: ${rem(22)};
    padding-bottom: ${rem(16.5)};
    padding-left: unset;
  }

  li {
  @media only screen and (min-width: 900px) {
    font-size: ${rem(14)};
    top: ${rem(-4)};
    position: relative;

  }
  @media only screen and (min-width: 1440px) {
    font-size: ${rem(18)};
  }
}

  `}
`;

const StyledLogo = styled(LogoMcc)`
  ${({ theme }) =>
    `
  margin-right: ${rem(24)};
  @media only screen and (min-width: ${theme.breakPoint}px) {
    margin-right: ${rem(48)};
  }
`}
`;

const PageHeader = ({ title = "", children = null }) => {
  return (
    <StyledPageHeader>
      {children}
      <StyledLogo />
    </StyledPageHeader>
  );
};

export default PageHeader;
