import styled from "styled-components";
import Icon from "./Icons";
import { rem } from "polished";

const StyledButton = styled.button`
  ${({ theme, $isOpen = false }) => `
  background: transparent;
  color: ${theme.color.key.dark};
  border: none;
  font-size: ${rem(16)};
  line-height: ${rem(16)};
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  justify-content: start;
  padding: ${rem(8)};
  text-transform: uppercase;
`}
`;

const StyledIcon = styled(Icon)`
  ${({ $isOpen = false }) => `
  transform: ${$isOpen ? `rotate(180deg)` : `rotate(0)`};
  margin-left: ${rem(8)};
  width: ${rem(14)};
  transition: transform .3s ease;
`}
`;

export const StyledDropDownContainer = styled.div`
  position: relative;
  display: flex;
  width: ${rem(200)};
  top: ${rem(-4)};
`;

const StyledDropDown = styled.ul`
  ${({ theme, $isOpen = false }) =>
    `
  background-color: ${theme.color.key.white};
  list-style: none;
  padding: 0;
  position: absolute;
  transition: max-height .3s ease;
  max-height: ${$isOpen ? rem(800) : 0};
  width: ${rem(200)};
  right: 0;
  top: 100%;
  overflow: hidden;
  z-index: 2;
  box-sizing: border-box;
  left: 0;
`}
`;

export const StyledDropDownItem = styled.li`
  ${({ theme }) => `
  padding: ${rem(12)} ${rem(8)};
  cursor: pointer;
  border-bottom: ${rem(1)} solid ${theme.color.key.default};
  text-align: left;
  color: ${theme.color.key.dartk};
  font-size: ${rem(16)}; 
  text-transform: uppercase;
  line-height: ${rem(24)};

  &:last-child{
    border-bottom: none;
  }

  &:hover{
    background-color: ${theme.color.ships.light};
    color: ${theme.color.key.dark};
  }
`}
`;

const Dropdown = ({
  selectedOption,
  onDropDownClick,
  isOpen,
  children = null,
}) => {
  return (
    <StyledDropDownContainer>
      <StyledButton onClick={onDropDownClick} $isOpen={isOpen}>
        {selectedOption}
        <StyledIcon id={"Dropdown"} $isOpen={isOpen} />
      </StyledButton>
      <StyledDropDown $isOpen={isOpen}>{children}</StyledDropDown>
    </StyledDropDownContainer>
  );
};

export default Dropdown;
