import YearLabel from "../components/YearLabel";
import { rem } from "polished";
import styled from "styled-components";
import LogoMcc from "./LogoMcc";

const StyledMapHeader = styled.div`
  ${({ theme }) => `
  position: absolute;
  width: 100%;
  padding: ${rem(24)} ${rem(48)} ${rem(24)} ${rem(20)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  border-bottom: solid ${rem(1)} ${theme.color.globalMenu.dark};
  pointer-events: none;

  `}
`;

const MapHeader = () => {
  return (
    <StyledMapHeader>
      <YearLabel />
      <LogoMcc />
    </StyledMapHeader>
  );
};

export default MapHeader;
