import styled from "styled-components";
import { rem } from "polished";

const StyledList = styled.ul`
  ${({ theme }) => `
    display: flex;
    flex-wrap: wrap;
    padding:${rem(16)} 0;

    @media only screen and (min-width: ${theme.breakPoint}px) {
      padding: ${rem(24)};
    }
  `}
`;

const OverviewList = ({ children = null }) => {
  return <StyledList>{children}</StyledList>;
};

export default OverviewList;
