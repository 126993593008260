import Icon from "./Icons";
import styled from "styled-components";
import { rem } from "polished";
import fontParser from "../utils/fontParser";
import { StyledLinks, StyledLink, StyledArchiveIcon } from "./MapDetailHeader";
import { StyledColumn } from "./CardBodyContent";
import { useTranslation } from "react-i18next";
import { fromString } from "../utils/nullables";
import { getTranslatedRole } from "../utils/helperFunctions";

const StyledDetailHeader = styled.div`
  ${({ theme }) => `
    color: ${theme.color.key.white};
    background-color: ${theme.color.persons.dark};
    padding: ${rem(22)} ${rem(16)};
    display: flex;
    position: relative;

    @media only screen and (min-width: ${theme.breakPoint}px) {
      padding: ${rem(24)} ${rem(16)};
      margin: ${rem(16)} ${rem(16)} 0 ${rem(16)};
   }
`}
`;

const StyledDetailHeaderContent = styled.div`
  ${({ theme }) =>
    `
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: ${theme.breakPoint}px) {
      justify-content: start;
    }
 `}
`;
export const StyledLabel = styled.span`
  ${({ theme }) => `
  ${fontParser("caption2")};
  display: block;
  color: ${theme.color.key.white};
  font-weight: normal;
  text-transform: uppercase;
  `}
`;

export const StyledValue = styled.span`
  ${({ theme }) => `
  ${fontParser("caption2")};
  color: ${theme.color.persons.secondary};
  font-weight: normal;
  text-transform: uppercase;
  margin-left: ${rem(4)};
  `}
`;

export const StyledHeaderTopTitle = styled.span`
  ${({ theme }) => `
  ${fontParser("body")};
  color: ${theme.color.persons.secondary};
  font-weight: normal;
  `}
`;

const StyledHeaderTitle = styled.h2`
  ${fontParser("title1")}
`;

const DetailHeader = ({ data, links = [], children = null }) => {
  const { t, i18n } = useTranslation();
  return (
    <StyledDetailHeader>
      <StyledDetailHeaderContent>
        <StyledHeaderTopTitle>
          {getTranslatedRole(data.rollen[0].rollen_id.rol, i18n.language)}
        </StyledHeaderTopTitle>

        <StyledHeaderTitle>{data.naam}</StyledHeaderTitle>
        {links && links.length > 0 && (
          <StyledLinks>
            {links.map(({ link, title }, id) => (
              <StyledLink
                key={id}
                href={link !== null ? link : ""}
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <StyledArchiveIcon id="Link" />
                {title}
              </StyledLink>
            ))}
          </StyledLinks>
        )}
        {!!data.geboortejaar && (
          <StyledColumn>
            <StyledLabel>{t("peopleDetail.dateOfBirth")}</StyledLabel>
            <StyledValue>{fromString(data.geboortejaar)}</StyledValue>
          </StyledColumn>
        )}
        {!!data.sterfjaar && (
          <StyledColumn>
            <StyledLabel>{t("peopleDetail.dateOfDeath")}</StyledLabel>
            <StyledValue>{fromString(data.sterfjaar)}</StyledValue>
          </StyledColumn>
        )}
        <StyledColumn>
          <StyledLabel>{t("peopleDetail.shareholder")}</StyledLabel>
          <StyledValue>
            {data.aandeelhouder_mcc
              ? `${t("peopleDetail.yes")}`
              : `${t("peopleDetail.no")}`}
          </StyledValue>
        </StyledColumn>
        {!!data.woonplaats && (
          <StyledColumn>
            <StyledLabel>{t("peopleDetail.residence")}</StyledLabel>
            <StyledValue>{data.woonplaats}</StyledValue>
          </StyledColumn>
        )}
        {children}
      </StyledDetailHeaderContent>
    </StyledDetailHeader>
  );
};

export default DetailHeader;
