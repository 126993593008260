import styled from "styled-components";
import { rem } from "polished";
import fontParser from "../utils/fontParser";

const StyledContainer = styled.div`
  ${({ theme }) =>
    `
    padding: ${rem(16)};
    display: flex;
    flex-wrap: wrap;
  `}
`;

export const StyledRow = styled.div`
  ${({ theme }) => `
  width: 100%;
  display: flex;
  flex-direction: column;
`}
`;

export const StyledColumn = styled.div`
  ${({ theme, $col = 12 }) => `
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: ${rem(4)};
  display: flex;
  align-items: baseline;

  @media only screen and (min-width: ${theme.breakPoint}px) {
    width: calc((${$col} / 12) * 100%);
  }
`}
`;

export const StyledLabel = styled.span`
  ${({ theme }) => `
  ${fontParser("caption2")}
  display: block;
  color: ${theme.color.key.primary};
  text-transform: uppercase;
  `}
`;

export const StyledValue = styled.span`
  font-weight: normal;
  display: block;
  margin-left: ${rem(4)};
`;

const CardBodyContent = ({ children }) => {
  return <StyledContainer>{children}</StyledContainer>;
};

export default CardBodyContent;
