import {
  ApolloProvider,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  gql,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";

// Axios queries
export const getPoints_0 = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/uploads/points/points-0.json`)
    .then(({ data }) => data);
};
export const getPoints_1 = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/uploads/points/points-1.json`)
    .then(({ data }) => data);
};
export const getPoints_2 = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/uploads/points/points-2.json`)
    .then(({ data }) => data);
};
export const getPoints_3 = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/uploads/points/points-3.json`)
    .then(({ data }) => data);
};
export const getPoints_4 = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/uploads/points/points-4.json`)
    .then(({ data }) => data);
};
export const getPoints_5 = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/uploads/points/points-5.json`)
    .then(({ data }) => data);
};
export const getPoints_6 = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/uploads/points/points-6.json`)
    .then(({ data }) => data);
};
export const getPoints_7 = async () => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/uploads/points/points-7.json`)
    .then(({ data }) => data);
};

export const getVoyages = async () => {
  return await axios
    .get(
      `${process.env.REACT_APP_HOST}/items/reizen?fields=*,bestemmingen.*,bestemmingen.bestemming.*&filter[count(bestemmingen)][_gte]=1&limit=300`
    )
    .then(({ data }) => data);
};

export const getVoyagesByShip = async (id) => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/items/reizen`, {
      params: {
        filter: {
          schip: {
            _eq: id,
          },
        },
      },
    })
    .then(({ data }) => data);
};

export const getVoyagesByPerson = async (id) => {
  return await axios
    .get(
      `${process.env.REACT_APP_HOST}/items/reizen?fields=*,bemanning.*&filter[bemanning][personen_id][_eq]=${id}`
    )
    .then(({ data }) => data);
};

export const getVoyageTypesByIDs = async (ids) => {
  return await axios
    .get(`${process.env.REACT_APP_HOST}/items/reis_types`, {
      params: {
        filter: {
          id: {
            _in: ids,
          },
        },
      },
    })
    .then(({ data }) => data);
};

export const getBlogsByLang = async (lang) => {
  const locale = lang === "nl" ? "nl-NL" : "en-US";

  return await axios
    .get(
      `${process.env.REACT_APP_HOST}/items/artikelen?fields[]=id&fields[]=afbeelding&fields[]=date_created&fields[]=status&fields[]=categories&fields[]=translations.*&deep[translations][_filter][languages_id][_eq]=${locale}`
    )
    .then(({ data }) => data);
};

export const getHelpPagesByLang = async (lang) => {
  const locale = lang === "nl" ? "nl-NL" : "en-US";

  return await axios
    .get(
      `${process.env.REACT_APP_HOST}/items/help_paginas?fields[]=id&fields[]=afbeelding&fields[]=date_created&fields[]=status&fields[]=translations.*&deep[translations][_filter][languages_code][_eq]=${locale}`
    )
    .then(({ data }) => data);
};

export const getBlogsBySlug = async (slug) => {
  return await axios
    .get(
      `${process.env.REACT_APP_HOST}/items/artikelen?fields[]=id&fields[]=status&fields[]=afbeelding&fields[]=date_created&fields[]=status&fields[]=categories&fields[]=translations.*&deep[translations][_filter][slug][_eq]=${slug}`
    )
    .then(({ data }) => data);
};

export const getBlogsByID = async (id) => {
  return await axios
    .get(
      `${process.env.REACT_APP_HOST}/items/artikelen?fields[]=id&fields[]=status&fields[]=afbeelding&fields[]=date_created&fields[]=status&fields[]=categories&fields[]=translations.*&[id][_eq]=${id}`
    )
    .then(({ data }) => data);
};

export const getArchiveItemsById = async (id) => {
  return await axios
    .get(
      `${process.env.REACT_APP_HOST}/items/archiefstukken?fields=*,entiteit.*&filter%5Bentiteit%5D%5Bitem%5D%5B_eq%5D=${id}`
    )
    .then(({ data }) => data);
};

export const getBlogItemsById = async (id, lang) => {
  const locale = lang === "nl" ? "nl-NL" : "en-US";
  return await axios
    .get(
      `${process.env.REACT_APP_HOST}/items/artikelen?fields=*,entiteit.*, translations.*&filter[entiteit][item][_eq]=${id}&fields[]=translations.*&deep[translations][_filter][languages_id][_eq]=${locale}`
    )
    .then(({ data }) => data);
};

// GQL queries

export const main = gql`
  query {
    bemannings_rollen(sort: ["order"]) {
      rol
      id
    }
    schip_types {
      id
      naam
    }
    reis_types {
      id
      naam
    }
    map_routes(limit: 200) {
      path
      bestemming1 {
        id
        plaats
      }
      bestemming2 {
        id
        plaats
      }
    }
    locaties {
      id
      marker
    }
    reizen {
      id
      schip {
        id
        naam
      }
      datum_aanvang_reis
      datum_einde_reis
      plaats_aanvang_reis {
        id
        plaats
        marker
      }
      plaats_einde_reis {
        id
        plaats
      }
      bestemmingen {
        bestemming {
          id
          plaats
        }
      }
    }
  }
`;

export const overviewShips = gql`
  query {
    schepen {
      naam
      aantal_reizen_mcc
      id
      scheepstype {
        id
        naam
      }
      icon_goederen
      icon_mensenhandel
      icon_onvoltooid
    }
  }
`;

export const journeyDetail = (id) => gql`
query {
  reizen_by_id(id:"${id}"){
    id,
    reisnummer,
    reden_onvoltooid {
      naam
    }
    aantal_bemanningsleden_vertrek,
    plaats_aanvang_reis{
      plaats
    },
    plaats_einde_reis{
      plaats
    },
    datum_aanvang_reis,
    datum_einde_reis,
    mensenhandel,
    aantal_mensen_gekocht,
    aantal_mensen_verkocht,
    type {
      naam
    },
    schip {
      naam
      aantal_reizen_mcc,
      id
    },
    bemanning{
      personen_id{
        aantal_schepen,
        naam
        id
      },
      rollen_id{
         rol
      }
    }
    bestemmingen{
      aankomst,
      vertrek,
      sort,
      reis{
        type{ 
          naam
        }
      },
      bestemming{
        plaats,
        id
      },
      goederen_ingekocht{
        id,
        goederen_id{
          id
          naam
          naam_en
        }
      },
      goederen_verkocht{
        id,
        goederen_id{
          id
          naam
          naam_en
        }
      },
      goederen_naar_huis {
        goederen_id {
            id
            naam
            naam_en
        }
      },
      mensen_ingekocht,
      mensen_verkocht,
      tussenstops
    } 
  }
 }
`;

export function shipByID(id) {
  return gql`
  query {
    schepen_by_id(id:"${id}"){
      id
      naam
      lengte
      breedte
      diepgang
      hoogte_tussendek
      inhoud
      lasten
      aantal_reizen_geannuleerd
      aantal_reizen_mcc
      aantal_reizen_verhuurd
      plaats_van_bouw
      herkomst_schip
      jaar_voltooiing_bouw
      scheepstimmerman
      translations {
        herkomst_schip_vertaling
        scheepstimmerman_vertaling 
        languages_code {
            code
        }
      }
      scheepstype{
       naam
      }
    } 
   }
  `;
}


// Reducing the query limit from 500 to a more appropriate value can enhance performance and mitigate potential lag issues. 
export const overviewPeople = gql`
  query {
    personen(limit: 500) {
      naam
      id
      aantal_schepen
      icon_mensenhandel
      rollen {
        rollen_id {
          rol
          id
        }
      }
    }
  }
`;

export function personByID(id) {
  return gql`
  query {
    personen_by_id(id:"${id}"){
      id
      naam
      aantal_schepen
      aandeelhouder_mcc
      beschrijving
      translations{
        beschrijving_vertaling
        languages_code{
            code
        }
    }
      geboortejaar
      sterfjaar
      woonplaats
      van_afrikaanse_caribische_afkomst
      echtgenote
      rollen {
        van
        tot
        rollen_id {
          rol
          id
        }
      }
      aantal_schepen
      woonplaats
      rollen {
        reizen_id {
          bestemmingen {
            bestemming {
              plaats
            }
          }
          plaats_aanvang_reis {
            plaats
          }
          plaats_einde_reis {
            plaats
          }
        }
      }
    }
   }
  `;
}

// bootstrap

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_HOST}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

const GraphqlProvider = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);

export default GraphqlProvider;
