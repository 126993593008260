import { useState as useGlobalState, useDispatch } from "../hooks/useReducer";
import PagePanel from "../components/PagePanel";
import PageHeader from "../components/PageHeader";
import { useEffect, useState } from "react";
import {
  StyledNavigation,
  StyledList,
  StyledListItem,
} from "../components/PageSubNavigation";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import styled from "styled-components";
import PageContent from "../components/PageContent";
import { rem } from "polished";
import Dropdown from "../components/Dropdown";
import {
  StyledDropDownContainer,
  StyledDropDownItem,
} from "../components/Dropdown";
import fontParser from "../utils/fontParser";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const StyledPageContent = styled(PageContent)`
  ${({ theme }) => `
      padding: ${rem(32)} ${rem(16)};

      @media only screen and (min-width: ${theme.breakPoint}px) {
        padding: ${rem(32)};
      }
  `}
`;

const StyledContentWrapper = styled.div`
  ${({ theme }) => `
  padding: 0 ${rem(16)};
  position: relative;

  h1{
    margin-bottom: ${rem(16)};
    ${fontParser("detailTitle")}
  }

  hr {
    margin: ${rem(32)} 0 ${rem(8)} 0;
    border-color: ${theme.color.globalMenu.primary};
  }

  a {
    color: ${theme.color.key.dark};
    font-weight: bold;
  }

  h2{
    ${fontParser("blogCaption")};
    margin-bottom: ${rem(4)};
  }

  h3{
    ${fontParser("title3")}
  }

  h2,
  h3,
  h4,
  h5,
  h6{
    margin-top: ${rem(4)};
    margin-bottom: ${rem(4)};
  }
  p:empty {
    display: none;
  }
  p {
    margin-bottom:  ${rem(12)};
  }
  p, ul li{
    font-size: ${rem(14)};
    line-height: ${rem(22)};
  }

  img {
    margin-right: ${rem(10)};
  }
  
  @media only screen and (min-width: ${theme.breakPoint}px) {
    padding: 0 ${rem(8)};
    p, ul li{
      font-size: ${rem(16)};
      line-height: ${rem(24)};
    }
  }
`}
`;

const StyledDropdownWrapper = styled(StyledDropDownContainer)`
  ${({ theme }) => `
    display: flex;
    @media only screen and (min-width: ${theme.breakPoint}px) {
      display: none;
    }
`}
`;

const Explain = () => {
  const { helpPages, helpPagesNavList } = useGlobalState();
  const [selectedNavItem, setSelectedNavItem] = useState(0);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { trackPageView, trackEvent } = useMatomo();

  // Track page view
  useEffect(() => {
    trackPageView({
      documentTitle: "Explain", // optional
    });
  }, []);

  useEffect(() => {
    if (helpPages !== null) {
      dispatch({
        type: "SET_HELP_PAGES_NAV_LIST",
        payload: helpPages.map(({ translations }) => translations[0]),
      });
    }
  }, [helpPages, dispatch]);

  const handleSubNavClick = (index) => {
    setSelectedNavItem(index);
  };

  const handlePageFilter = (index) => {
    setSelectedNavItem(index);
    setIsOpenDropdown(false);
  };

  const handleOpenFilter = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  return (
    <PagePanel>
      <PageHeader title={t("explain.title")}>
        <StyledNavigation $isPage={true}>
          <StyledList>
            {helpPagesNavList.map((item, index) => {
              return (
                <StyledListItem
                  key={index}
                  $active={selectedNavItem === index}
                  onClick={() => handleSubNavClick(index)}
                >
                  {item.naam}
                </StyledListItem>
              );
            })}
          </StyledList>
        </StyledNavigation>
        <StyledDropdownWrapper>
          <Dropdown
            isOpen={isOpenDropdown}
            selectedOption={t(`explain.menu`)}
            items={helpPagesNavList}
            onDataClick={handlePageFilter}
            onDropDownClick={handleOpenFilter}
          >
            {helpPagesNavList.map((page, index) => {
              return (
                <StyledDropDownItem
                  key={index + 1}
                  onClick={() => handlePageFilter(index)}
                >
                  {page.naam}
                </StyledDropDownItem>
              );
            })}
          </Dropdown>
        </StyledDropdownWrapper>
      </PageHeader>
      {helpPages &&
        helpPages
          .filter((page, index) => index === selectedNavItem)
          .map((page, i) => (
            <StyledPageContent key={i}>
              <StyledContentWrapper>
                <h1>{page.translations[0].naam}</h1>
                {parse(page.translations[0].inhoud)}
              </StyledContentWrapper>
            </StyledPageContent>
          ))}
    </PagePanel>
  );
};

export default Explain;
