import styled from "styled-components";

const StyledLogo = styled.div``;

const LogoMcc = ({ className }) => {
  const st0 = {
    fill: "#71C8E5",
  };
  const st1 = {
    fill: "#4A0E12",
  };
  const st2 = {
    fill: "#E05115",
  };
  return (
    <StyledLogo>
      <div className={className}>
        <svg
          width="210px"
          height="44px"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 398.2 82.6"
        >
          <g>
            <path
              style={st0}
              d="M140.6,75.7l17.1-34.2l17.1-34.2h-34.2c-18.9,0-34.2,15.3-34.2,34.2S121.8,75.7,140.6,75.7z"
            />
          </g>
          <polygon style={st1} points="157.7,41.6 140.6,75.7 174.8,75.7 " />
          <polygon style={st1} points="55.2,41.6 38.1,75.7 72.3,75.7 " />
          <polyline
            style={st0}
            points="72.3,75.7 106.5,75.7 89.4,41.6 89.4,41.6 89.4,41.6 89.4,41.6 89.4,41.6 72.3,7.4 55.2,41.6 
	55.2,41.6 55.2,41.6 55.2,41.6 55.2,41.6 72.3,75.7 "
          />
          <line style={st0} x1="89.4" y1="41.6" x2="89.4" y2="41.6" />
          <polygon
            style={st2}
            points="55.2,41.6 55.2,41.6 55.2,41.6 55.2,41.6 38.1,7.4 21,41.6 21,41.6 21,41.6 21,41.6 21,41.6 3.9,75.7 
	38.1,75.7 38.1,75.7 55.2,41.6 "
          />
          <g>
            <path
              style={st2}
              d="M209,75.7l17.1-34.2l17.1-34.2H209c-18.9,0-34.2,15.3-34.2,34.2S190.1,75.7,209,75.7z"
            />
          </g>
          <polygon style={st1} points="226.1,41.6 209,75.7 243.2,75.7 " />
          <g>
            <path
              style={st1}
              d="M258.7,8.3h2.3l3.5,8.3c0.3,0.8,0.8,2.1,0.8,2.1h0c0,0,0.4-1.2,0.8-2.1l3.5-8.3h2.3l1.2,15.4H271l-0.8-9.7
		c-0.1-0.9,0-2.3,0-2.3h0c0,0-0.5,1.4-0.8,2.3l-3.1,6.9h-1.9l-3-6.9c-0.3-0.8-0.8-2.3-0.8-2.3h0c0,0,0,1.4,0,2.3l-0.7,9.7h-2.2
		L258.7,8.3z"
            />
            <path
              style={st1}
              d="M276.4,8.3h2.1v2.2h-2.1V8.3z M276.4,12.7h2.1v11h-2.1V12.7z"
            />
            <path
              style={st1}
              d="M286,12.4c2.5,0,3.4,1.7,3.4,1.7h0c0,0,0-0.3,0-0.7V8.3h2.1v15.4h-2v-1c0-0.4,0-0.7,0-0.7h0c0,0-0.9,2-3.6,2
		c-3,0-4.8-2.3-4.8-5.8C281.1,14.7,283.1,12.4,286,12.4z M286.3,22.1c1.6,0,3.2-1.2,3.2-4c0-2-1-3.9-3.1-3.9c-1.7,0-3.2,1.4-3.2,3.9
		C283.2,20.6,284.5,22.1,286.3,22.1z"
            />
            <path
              style={st1}
              d="M299.1,12.4c2.5,0,3.4,1.7,3.4,1.7h0c0,0,0-0.3,0-0.7V8.3h2.1v15.4h-2v-1c0-0.4,0-0.7,0-0.7h0c0,0-0.9,2-3.6,2
		c-3,0-4.8-2.3-4.8-5.8C294.1,14.7,296.2,12.4,299.1,12.4z M299.4,22.1c1.6,0,3.2-1.2,3.2-4c0-2-1-3.9-3.1-3.9
		c-1.7,0-3.2,1.4-3.2,3.9C296.3,20.6,297.6,22.1,299.4,22.1z"
            />
            <path
              style={st1}
              d="M312.7,12.4c3.1,0,4.8,2.3,4.8,5.2c0,0.3-0.1,0.9-0.1,0.9h-8c0.1,2.4,1.8,3.6,3.8,3.6c1.9,0,3.3-1.3,3.3-1.3
		l0.9,1.5c0,0-1.6,1.6-4.3,1.6c-3.5,0-5.8-2.5-5.8-5.8C307.2,14.7,309.5,12.4,312.7,12.4z M315.3,16.9c-0.1-1.9-1.2-2.8-2.7-2.8
		c-1.6,0-2.9,1-3.2,2.8H315.3z"
            />
            <path
              style={st1}
              d="M319.9,8.3h2.1v12c0,1.3,0.5,1.6,1.2,1.6c0.2,0,0.4,0,0.4,0v1.9c0,0-0.3,0-0.7,0c-1.2,0-3-0.3-3-3.1V8.3z"
            />
            <path
              style={st1}
              d="M325.8,8.3h2.1v5c0,0.5,0,0.9,0,0.9h0c0,0,0.9-1.9,3.5-1.9c3,0,4.8,2.3,4.8,5.8c0,3.5-2.1,5.8-5,5.8
		c-2.5,0-3.5-1.9-3.5-1.9h0c0,0,0,0.3,0,0.8v0.8h-2V8.3z M330.9,22.1c1.7,0,3.2-1.4,3.2-3.9c0-2.4-1.3-3.9-3.1-3.9
		c-1.7,0-3.2,1.2-3.2,4C327.8,20.2,328.8,22.1,330.9,22.1z"
            />
            <path
              style={st1}
              d="M338.7,12.7h2.1v6.6c0,1.5,0.3,2.7,2.1,2.7c2.2,0,3.6-2,3.6-4.2v-5.2h2.1v11h-2v-1.5c0-0.5,0-0.8,0-0.8h0
		c-0.5,1.1-1.9,2.5-4,2.5c-2.5,0-3.8-1.3-3.8-4.2V12.7z"
            />
            <path
              style={st1}
              d="M351.8,12.7h2v1.9c0,0.5,0,0.8,0,0.8h0c0.5-1.6,1.8-2.9,3.5-2.9c0.3,0,0.5,0,0.5,0v2.1c0,0-0.3-0.1-0.6-0.1
		c-1.3,0-2.6,1-3.1,2.6c-0.2,0.6-0.3,1.3-0.3,2v4.5h-2.1V12.7z"
            />
            <path
              style={st1}
              d="M364,26.4c1.9,0,3.5-0.8,3.5-3.1v-0.8c0-0.3,0-0.7,0-0.7h0c-0.7,1.1-1.7,1.7-3.3,1.7c-3.1,0-4.9-2.5-4.9-5.6
		c0-3.2,1.8-5.5,4.8-5.5c2.7,0,3.5,1.6,3.5,1.6h0c0,0,0-0.2,0-0.3v-1h2v10.5c0,3.6-2.8,5-5.5,5c-1.3,0-2.7-0.4-3.8-0.9l0.7-1.7
		C361,25.7,362.3,26.4,364,26.4z M367.5,17.9c0-2.9-1.4-3.7-3.1-3.7c-1.9,0-3,1.4-3,3.6c0,2.3,1.2,3.8,3.2,3.8
		C366.1,21.7,367.5,20.8,367.5,17.9z"
            />
            <path
              style={st1}
              d="M373,20.8c0,0,1.2,1.3,3.2,1.3c0.9,0,1.8-0.5,1.8-1.4c0-2-5.7-1.6-5.7-5.2c0-2,1.8-3.1,4-3.1
		c2.4,0,3.5,1.2,3.5,1.2l-0.8,1.6c0,0-1-1-2.7-1c-0.9,0-1.8,0.4-1.8,1.3c0,2,5.7,1.5,5.7,5.2c0,1.8-1.6,3.2-4,3.2
		c-2.7,0-4.2-1.6-4.2-1.6L373,20.8z"
            />
            <path
              style={st1}
              d="M387.4,12.4c3.1,0,4.8,2.3,4.8,5.2c0,0.3-0.1,0.9-0.1,0.9h-8c0.1,2.4,1.8,3.6,3.8,3.6c1.9,0,3.3-1.3,3.3-1.3
		l0.9,1.5c0,0-1.6,1.6-4.3,1.6c-3.5,0-5.8-2.5-5.8-5.8C381.9,14.7,384.2,12.4,387.4,12.4z M390,16.9c-0.1-1.9-1.2-2.8-2.7-2.8
		c-1.6,0-2.9,1-3.2,2.8H390z"
            />
          </g>
          <g>
            <path
              style={st1}
              d="M264.7,34.2c3.7,0,5.6,2,5.6,2l-1.1,1.6c0,0-1.8-1.7-4.4-1.7c-3.4,0-5.7,2.6-5.7,5.9c0,3.3,2.3,6.1,5.7,6.1
		c2.9,0,4.7-2,4.7-2l1.2,1.5c0,0-2.1,2.4-6,2.4c-4.6,0-7.8-3.5-7.8-8C256.9,37.6,260.3,34.2,264.7,34.2z"
            />
            <path
              style={st1}
              d="M278.4,38.5c3.3,0,5.9,2.4,5.9,5.7c0,3.3-2.7,5.8-5.9,5.8s-5.9-2.4-5.9-5.8C272.4,40.9,275.1,38.5,278.4,38.5z
		 M278.4,48.2c2.1,0,3.8-1.7,3.8-4c0-2.3-1.7-3.9-3.8-3.9c-2.1,0-3.8,1.6-3.8,3.9C274.6,46.6,276.3,48.2,278.4,48.2z"
            />
            <path
              style={st1}
              d="M286.8,38.8h2v1.5c0,0.4,0,0.8,0,0.8h0c0.5-1.3,2.1-2.5,3.8-2.5c1.8,0,2.9,0.8,3.3,2.5h0
		c0.6-1.3,2.1-2.5,3.9-2.5c2.5,0,3.6,1.4,3.6,4.2v7h-2.1v-6.6c0-1.5-0.3-2.8-2-2.8c-1.5,0-2.7,1.3-3.1,2.8c-0.1,0.5-0.2,1-0.2,1.5v5
		h-2.1v-6.6c0-1.4-0.2-2.8-1.9-2.8c-1.6,0-2.7,1.3-3.2,2.9c-0.1,0.4-0.1,0.9-0.1,1.4v5h-2.1V38.8z"
            />
            <path
              style={st1}
              d="M306.7,38.8h2v1.5c0,0.4,0,0.8,0,0.8h0c0.5-1.3,2.1-2.5,3.8-2.5c1.8,0,2.9,0.8,3.3,2.5h0
		c0.6-1.3,2.1-2.5,3.9-2.5c2.5,0,3.6,1.4,3.6,4.2v7h-2.1v-6.6c0-1.5-0.3-2.8-2-2.8c-1.5,0-2.7,1.3-3.1,2.8c-0.1,0.5-0.2,1-0.2,1.5v5
		H314v-6.6c0-1.4-0.2-2.8-1.9-2.8c-1.6,0-2.7,1.3-3.2,2.9c-0.1,0.4-0.1,0.9-0.1,1.4v5h-2.1V38.8z"
            />
            <path
              style={st1}
              d="M331.3,38.5c3.1,0,4.8,2.3,4.8,5.2c0,0.3-0.1,0.9-0.1,0.9h-8c0.1,2.4,1.8,3.6,3.8,3.6c1.9,0,3.3-1.3,3.3-1.3
		l0.9,1.5c0,0-1.6,1.6-4.3,1.6c-3.5,0-5.8-2.5-5.8-5.8C325.8,40.8,328.2,38.5,331.3,38.5z M333.9,43c-0.1-1.9-1.2-2.8-2.7-2.8
		c-1.6,0-2.9,1-3.2,2.8H333.9z"
            />
            <path
              style={st1}
              d="M338.6,38.8h2v1.9c0,0.5,0,0.8,0,0.8h0c0.5-1.6,1.8-2.9,3.5-2.9c0.3,0,0.5,0,0.5,0v2.1c0,0-0.3-0.1-0.6-0.1
		c-1.3,0-2.6,1-3.1,2.6c-0.2,0.6-0.3,1.3-0.3,2v4.5h-2.1V38.8z"
            />
            <path
              style={st1}
              d="M351.9,38.5c2.8,0,4.1,1.6,4.1,1.6l-1,1.5c0,0-1.2-1.3-3-1.3c-2.2,0-3.8,1.7-3.8,3.9c0,2.2,1.6,4,3.9,4
		c2,0,3.4-1.5,3.4-1.5l0.8,1.5c0,0-1.5,1.8-4.4,1.8c-3.5,0-5.9-2.5-5.9-5.8C346,41,348.4,38.5,351.9,38.5z"
            />
            <path
              style={st1}
              d="M358.6,34.4h2.1v2.2h-2.1V34.4z M358.7,38.8h2.1v11h-2.1V38.8z"
            />
            <path
              style={st1}
              d="M368.8,38.5c3.1,0,4.8,2.3,4.8,5.2c0,0.3-0.1,0.9-0.1,0.9h-8c0.1,2.4,1.8,3.6,3.8,3.6c1.9,0,3.3-1.3,3.3-1.3
		l0.9,1.5c0,0-1.6,1.6-4.3,1.6c-3.5,0-5.8-2.5-5.8-5.8C363.3,40.8,365.7,38.5,368.8,38.5z M371.4,43c-0.1-1.9-1.2-2.8-2.7-2.8
		c-1.6,0-2.9,1-3.2,2.8H371.4z"
            />
          </g>
          <g>
            <path
              style={st1}
              d="M264.7,60.3c3.7,0,5.6,2,5.6,2l-1.1,1.6c0,0-1.8-1.7-4.4-1.7c-3.4,0-5.7,2.6-5.7,5.9c0,3.3,2.3,6.1,5.7,6.1
		c2.9,0,4.7-2,4.7-2l1.2,1.5c0,0-2.1,2.4-6,2.4c-4.6,0-7.8-3.5-7.8-8C256.9,63.7,260.3,60.3,264.7,60.3z"
            />
            <path
              style={st1}
              d="M278.4,64.6c3.3,0,5.9,2.4,5.9,5.7c0,3.3-2.7,5.8-5.9,5.8s-5.9-2.4-5.9-5.8C272.4,67,275.1,64.6,278.4,64.6z
		 M278.4,74.3c2.1,0,3.8-1.7,3.8-4c0-2.3-1.7-3.9-3.8-3.9c-2.1,0-3.8,1.6-3.8,3.9C274.6,72.7,276.3,74.3,278.4,74.3z"
            />
            <path
              style={st1}
              d="M286.8,64.9h2v1.5c0,0.4,0,0.8,0,0.8h0c0.5-1.3,2.1-2.5,3.8-2.5c1.8,0,2.9,0.8,3.3,2.5h0
		c0.6-1.3,2.1-2.5,3.9-2.5c2.5,0,3.6,1.4,3.6,4.2v7h-2.1v-6.6c0-1.5-0.3-2.8-2-2.8c-1.5,0-2.7,1.3-3.1,2.8c-0.1,0.5-0.2,1-0.2,1.5v5
		h-2.1v-6.6c0-1.4-0.2-2.8-1.9-2.8c-1.6,0-2.7,1.3-3.2,2.9c-0.1,0.4-0.1,0.9-0.1,1.4v5h-2.1V64.9z"
            />
            <path
              style={st1}
              d="M306.7,64.9h1.9v0.9c0,0.4,0,0.8,0,0.8h0c0,0,0.9-2,3.7-2c3,0,4.8,2.3,4.8,5.8c0,3.5-2.1,5.8-5,5.8
		c-2.4,0-3.4-1.8-3.4-1.8h0c0,0,0,0.4,0,1v4.9h-2.1V64.9z M311.8,74.3c1.7,0,3.2-1.4,3.2-3.9c0-2.4-1.3-3.9-3.1-3.9
		c-1.7,0-3.2,1.2-3.2,4C308.7,72.4,309.8,74.3,311.8,74.3z"
            />
            <path
              style={st1}
              d="M325.5,69h0.5v-0.2c0-1.8-1-2.4-2.4-2.4c-1.7,0-3.1,1.1-3.1,1.1l-0.9-1.5c0,0,1.6-1.3,4.1-1.3
		c2.8,0,4.3,1.5,4.3,4.3v7h-2v-1c0-0.5,0-0.9,0-0.9h0c0,0-0.9,2.2-3.5,2.2c-1.9,0-3.7-1.2-3.7-3.3C318.8,69.2,323.6,69,325.5,69z
		 M323,74.5c1.8,0,2.9-1.9,2.9-3.5v-0.3h-0.5c-1.6,0-4.5,0.1-4.5,2.1C321,73.6,321.7,74.5,323,74.5z"
            />
            <path
              style={st1}
              d="M335.2,78.6c1.9,0,3.5-0.8,3.5-3.1v-0.8c0-0.3,0-0.7,0-0.7h0c-0.7,1.1-1.7,1.7-3.3,1.7c-3.1,0-4.9-2.5-4.9-5.6
		c0-3.2,1.8-5.5,4.8-5.5c2.7,0,3.5,1.6,3.5,1.6h0c0,0,0-0.2,0-0.3v-1h2v10.5c0,3.6-2.8,5-5.5,5c-1.3,0-2.7-0.4-3.8-0.9l0.7-1.7
		C332.2,77.9,333.6,78.6,335.2,78.6z M338.7,70.1c0-2.9-1.4-3.7-3.1-3.7c-1.9,0-3,1.4-3,3.6c0,2.3,1.2,3.8,3.2,3.8
		C337.4,73.9,338.7,73,338.7,70.1z"
            />
            <path
              style={st1}
              d="M344.1,64.9h2v1.5c0,0.4,0,0.8,0,0.8h0c0.4-1,1.7-2.5,4.1-2.5c2.6,0,3.8,1.4,3.8,4.2v7H352v-6.6
		c0-1.5-0.3-2.8-2.1-2.8c-1.7,0-3,1.1-3.5,2.7c-0.1,0.4-0.2,0.9-0.2,1.5v5.2h-2.1V64.9z"
            />
            <path
              style={st1}
              d="M357.2,60.5h2.1v2.2h-2.1V60.5z M357.2,64.9h2.1v11h-2.1V64.9z"
            />
            <path
              style={st1}
              d="M367.4,64.6c3.1,0,4.8,2.3,4.8,5.2c0,0.3-0.1,0.9-0.1,0.9h-8c0.1,2.4,1.8,3.6,3.8,3.6c1.9,0,3.3-1.3,3.3-1.3
		l0.9,1.5c0,0-1.6,1.6-4.3,1.6c-3.5,0-5.8-2.5-5.8-5.8C361.9,66.9,364.3,64.6,367.4,64.6z M370,69.1c-0.1-1.9-1.2-2.8-2.7-2.8
		c-1.6,0-2.9,1-3.2,2.8H370z"
            />
          </g>
        </svg>
      </div>
    </StyledLogo>
  );
};

export default LogoMcc;
