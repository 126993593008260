import Driehoeksreis from "../assets/icon-1.png";
import Driehoeksreis_1 from "../assets/icon-1-1.png";
import Driehoeksreis_2 from "../assets/icon-1-2.png";
import Driehoeksreis_3 from "../assets/icon-1-3.png";
import Driehoeksreis_4 from "../assets/icon-1-4.png";
import Driehoeksreis_slavenhandel from "../assets/icon-1-slavenhandel.png";
import Driehoeksreis_slavenhandel_1 from "../assets/icon-1-slavenhandel-1.png";
import Driehoeksreis_slavenhandel_2 from "../assets/icon-1-slavenhandel-2.png";
import Driehoeksreis_slavenhandel_3 from "../assets/icon-1-slavenhandel-3.png";
import Driehoeksreis_slavenhandel_4 from "../assets/icon-1-slavenhandel-4.png";

import WestAfrikaReis from "../assets/icon-2.png";
import WestAfrikaReis_1 from "../assets/icon-2-1.png";
import WestAfrikaReis_2 from "../assets/icon-2-2.png";
import WestAfrikaReis_3 from "../assets/icon-2-3.png";
import WestAfrikaReis_4 from "../assets/icon-2-4.png";
import WestAfrikaReis_slavenhandel from "../assets/icon-2-slavenhandel.png";
import WestAfrikaReis_slavenhandel_1 from "../assets/icon-2-slavenhandel-1.png";
import WestAfrikaReis_slavenhandel_2 from "../assets/icon-2-slavenhandel-2.png";
import WestAfrikaReis_slavenhandel_3 from "../assets/icon-2-slavenhandel-3.png";
import WestAfrikaReis_slavenhandel_4 from "../assets/icon-2-slavenhandel-4.png";

import NederlandseCaribenReis from "../assets/icon-3.png";
import NederlandseCaribenReis_1 from "../assets/icon-3-1.png";
import NederlandseCaribenReis_2 from "../assets/icon-3-2.png";
import NederlandseCaribenReis_3 from "../assets/icon-3-3.png";
import NederlandseCaribenReis_4 from "../assets/icon-3-4.png";
import NederlandseCaribenReis_slavenhandel from "../assets/icon-3-slavenhandel.png";
import NederlandseCaribenReis_slavenhandel_1 from "../assets/icon-3-slavenhandel-1.png";
import NederlandseCaribenReis_slavenhandel_2 from "../assets/icon-3-slavenhandel-2.png";
import NederlandseCaribenReis_slavenhandel_3 from "../assets/icon-3-slavenhandel-3.png";
import NederlandseCaribenReis_slavenhandel_4 from "../assets/icon-3-slavenhandel-4.png";

import SpaanseCaribenReis from "../assets/icon-4.png";
import SpaanseCaribenReis_1 from "../assets/icon-4-1.png";
import SpaanseCaribenReis_2 from "../assets/icon-4-2.png";
import SpaanseCaribenReis_3 from "../assets/icon-4-3.png";
import SpaanseCaribenReis_4 from "../assets/icon-4-4.png";
import SpaanseCaribenReis_slavenhandel from "../assets/icon-4-slavenhandel.png";
import SpaanseCaribenReis_slavenhandel_1 from "../assets/icon-4-slavenhandel-1.png";
import SpaanseCaribenReis_slavenhandel_2 from "../assets/icon-4-slavenhandel-2.png";
import SpaanseCaribenReis_slavenhandel_3 from "../assets/icon-4-slavenhandel-3.png";
import SpaanseCaribenReis_slavenhandel_4 from "../assets/icon-4-slavenhandel-4.png";

import VerhuurdAanAdmiraliteit from "../assets/icon-5.png";
import VerhuurdAanAdmiraliteit_1 from "../assets/icon-5-1.png";
import VerhuurdAanAdmiraliteit_2 from "../assets/icon-5-2.png";
import VerhuurdAanAdmiraliteit_3 from "../assets/icon-5-3.png";
import VerhuurdAanAdmiraliteit_4 from "../assets/icon-5-4.png";
import VerhuurdAanAdmiraliteit_slavenhandel from "../assets/icon-5-slavenhandel.png";
import VerhuurdAanAdmiraliteit_slavenhandel_1 from "../assets/icon-5-slavenhandel-1.png";
import VerhuurdAanAdmiraliteit_slavenhandel_2 from "../assets/icon-5-slavenhandel-2.png";
import VerhuurdAanAdmiraliteit_slavenhandel_3 from "../assets/icon-5-slavenhandel-3.png";
import VerhuurdAanAdmiraliteit_slavenhandel_4 from "../assets/icon-5-slavenhandel-4.png";

import KaperReis from "../assets/icon-6.png";
import KaperReis_1 from "../assets/icon-6-1.png";
import KaperReis_2 from "../assets/icon-6-2.png";
import KaperReis_3 from "../assets/icon-6-3.png";
import KaperReis_4 from "../assets/icon-6-4.png";
import KaperReis_slavenhandel from "../assets/icon-6-slavenhandel.png";
import KaperReis_slavenhandel_1 from "../assets/icon-6-slavenhandel-1.png";
import KaperReis_slavenhandel_2 from "../assets/icon-6-slavenhandel-2.png";
import KaperReis_slavenhandel_3 from "../assets/icon-6-slavenhandel-3.png";
import KaperReis_slavenhandel_4 from "../assets/icon-6-slavenhandel-4.png";

import VerkenningsReis from "../assets/icon-7.png";
import VerkenningsReis_1 from "../assets/icon-7-1.png";
import VerkenningsReis_2 from "../assets/icon-7-2.png";
import VerkenningsReis_3 from "../assets/icon-7-3.png";
import VerkenningsReis_4 from "../assets/icon-7-4.png";
import VerkenningsReis_slavenhandel from "../assets/icon-7-slavenhandel.png";
import VerkenningsReis_slavenhandel_1 from "../assets/icon-7-slavenhandel-1.png";
import VerkenningsReis_slavenhandel_2 from "../assets/icon-7-slavenhandel-2.png";
import VerkenningsReis_slavenhandel_3 from "../assets/icon-7-slavenhandel-3.png";
import VerkenningsReis_slavenhandel_4 from "../assets/icon-7-slavenhandel-4.png";

import EuropaReis from "../assets/icon-8.png";
import EuropaReis_1 from "../assets/icon-8-1.png";
import EuropaReis_2 from "../assets/icon-8-2.png";
import EuropaReis_3 from "../assets/icon-8-3.png";
import EuropaReis_4 from "../assets/icon-8-4.png";
import EuropaReis_slavenhandel from "../assets/icon-8-slavenhandel.png";
import EuropaReis_slavenhandel_1 from "../assets/icon-8-slavenhandel-1.png";
import EuropaReis_slavenhandel_2 from "../assets/icon-8-slavenhandel-2.png";
import EuropaReis_slavenhandel_3 from "../assets/icon-8-slavenhandel-3.png";
import EuropaReis_slavenhandel_4 from "../assets/icon-8-slavenhandel-4.png";

export const icons = [
  {
    url: Driehoeksreis,
    id: "icon-1",
  },
  {
    url: Driehoeksreis_slavenhandel,
    id: "icon-1-slavenhandel",
  },
  {
    url: Driehoeksreis_1,
    id: "icon-1-1",
  },
  {
    url: Driehoeksreis_2,
    id: "icon-1-2",
  },
  {
    url: Driehoeksreis_3,
    id: "icon-1-3",
  },
  {
    url: Driehoeksreis_4,
    id: "icon-1-4",
  },
  {
    url: Driehoeksreis_slavenhandel_1,
    id: "icon-1-slavenhandel-1",
  },
  {
    url: Driehoeksreis_slavenhandel_2,
    id: "icon-1-slavenhandel-2",
  },
  {
    url: Driehoeksreis_slavenhandel_3,
    id: "icon-1-slavenhandel-3",
  },
  {
    url: Driehoeksreis_slavenhandel_4,
    id: "icon-1-slavenhandel-4",
  },
  {
    url: WestAfrikaReis,
    id: "icon-2",
  },
  {
    url: WestAfrikaReis_slavenhandel,
    id: "icon-2-slavenhandel",
  },
  {
    url: WestAfrikaReis_1,
    id: "icon-2-1",
  },
  {
    url: WestAfrikaReis_2,
    id: "icon-2-2",
  },
  {
    url: WestAfrikaReis_3,
    id: "icon-2-3",
  },
  {
    url: WestAfrikaReis_4,
    id: "icon-2-4",
  },
  {
    url: WestAfrikaReis_slavenhandel_1,
    id: "icon-2-slavenhandel-1",
  },
  {
    url: WestAfrikaReis_slavenhandel_2,
    id: "icon-2-slavenhandel-2",
  },
  {
    url: WestAfrikaReis_slavenhandel_3,
    id: "icon-2-slavenhandel-3",
  },
  {
    url: WestAfrikaReis_slavenhandel_4,
    id: "icon-2-slavenhandel-4",
  },
  {
    url: NederlandseCaribenReis,
    id: "icon-3",
  },
  {
    url: NederlandseCaribenReis_slavenhandel,
    id: "icon-3-slavenhandel",
  },
  {
    url: NederlandseCaribenReis_1,
    id: "icon-3-1",
  },
  {
    url: NederlandseCaribenReis_2,
    id: "icon-3-2",
  },
  {
    url: NederlandseCaribenReis_3,
    id: "icon-3-3",
  },
  {
    url: NederlandseCaribenReis_4,
    id: "icon-3-4",
  },
  {
    url: NederlandseCaribenReis_slavenhandel_1,
    id: "icon-3-slavenhandel-1",
  },
  {
    url: NederlandseCaribenReis_slavenhandel_2,
    id: "icon-3-slavenhandel-2",
  },
  {
    url: NederlandseCaribenReis_slavenhandel_3,
    id: "icon-3-slavenhandel-3",
  },
  {
    url: NederlandseCaribenReis_slavenhandel_4,
    id: "icon-3-slavenhandel-4",
  },
  {
    url: SpaanseCaribenReis,
    id: "icon-4",
  },
  {
    url: SpaanseCaribenReis_slavenhandel,
    id: "icon-4-slavenhandel",
  },
  {
    url: SpaanseCaribenReis_1,
    id: "icon-4-1",
  },
  {
    url: SpaanseCaribenReis_2,
    id: "icon-4-2",
  },
  {
    url: SpaanseCaribenReis_3,
    id: "icon-4-3",
  },
  {
    url: SpaanseCaribenReis_4,
    id: "icon-4-4",
  },
  {
    url: SpaanseCaribenReis_slavenhandel_1,
    id: "icon-4-slavenhandel-1",
  },
  {
    url: SpaanseCaribenReis_slavenhandel_2,
    id: "icon-4-slavenhandel-2",
  },
  {
    url: SpaanseCaribenReis_slavenhandel_3,
    id: "icon-4-slavenhandel-3",
  },
  {
    url: SpaanseCaribenReis_slavenhandel_4,
    id: "icon-4-slavenhandel-4",
  },
  {
    url: VerhuurdAanAdmiraliteit,
    id: "icon-5",
  },
  {
    url: VerhuurdAanAdmiraliteit_slavenhandel,
    id: "icon-5-slavenhandel",
  },
  {
    url: VerhuurdAanAdmiraliteit_1,
    id: "icon-5-1",
  },
  {
    url: VerhuurdAanAdmiraliteit_2,
    id: "icon-5-2",
  },
  {
    url: VerhuurdAanAdmiraliteit_3,
    id: "icon-5-3",
  },
  {
    url: VerhuurdAanAdmiraliteit_4,
    id: "icon-5-4",
  },
  {
    url: VerhuurdAanAdmiraliteit_slavenhandel_1,
    id: "icon-5-slavenhandel-1",
  },
  {
    url: VerhuurdAanAdmiraliteit_slavenhandel_2,
    id: "icon-5-slavenhandel-2",
  },
  {
    url: VerhuurdAanAdmiraliteit_slavenhandel_3,
    id: "icon-5-slavenhandel-3",
  },
  {
    url: VerhuurdAanAdmiraliteit_slavenhandel_4,
    id: "icon-5-slavenhandel-4",
  },
  {
    url: KaperReis,
    id: "icon-6",
  },
  {
    url: KaperReis_slavenhandel,
    id: "icon-6-slavenhandel",
  },
  {
    url: KaperReis_1,
    id: "icon-6-1",
  },
  {
    url: KaperReis_2,
    id: "icon-6-2",
  },
  {
    url: KaperReis_3,
    id: "icon-6-3",
  },
  {
    url: KaperReis_4,
    id: "icon-6-4",
  },
  {
    url: KaperReis_slavenhandel_1,
    id: "icon-6-slavenhandel-1",
  },
  {
    url: KaperReis_slavenhandel_2,
    id: "icon-6-slavenhandel-2",
  },
  {
    url: KaperReis_slavenhandel_3,
    id: "icon-6-slavenhandel-3",
  },
  {
    url: KaperReis_slavenhandel_4,
    id: "icon-6-slavenhandel-4",
  },
  {
    url: VerkenningsReis,
    id: "icon-7",
  },
  {
    url: VerkenningsReis_slavenhandel,
    id: "icon-7-slavenhandel",
  },
  {
    url: VerkenningsReis_1,
    id: "icon-7-1",
  },
  {
    url: VerkenningsReis_2,
    id: "icon-7-2",
  },
  {
    url: VerkenningsReis_3,
    id: "icon-7-3",
  },
  {
    url: VerkenningsReis_4,
    id: "icon-7-4",
  },
  {
    url: VerkenningsReis_slavenhandel_1,
    id: "icon-7-slavenhandel-1",
  },
  {
    url: VerkenningsReis_slavenhandel_2,
    id: "icon-7-slavenhandel-2",
  },
  {
    url: VerkenningsReis_slavenhandel_3,
    id: "icon-7-slavenhandel-3",
  },
  {
    url: VerkenningsReis_slavenhandel_4,
    id: "icon-7-slavenhandel-4",
  },
  {
    url: EuropaReis,
    id: "icon-8",
  },
  {
    url: EuropaReis_slavenhandel,
    id: "icon-8-slavenhandel",
  },
  {
    url: EuropaReis_1,
    id: "icon-8-1",
  },
  {
    url: EuropaReis_2,
    id: "icon-8-2",
  },
  {
    url: EuropaReis_3,
    id: "icon-8-3",
  },
  {
    url: EuropaReis_4,
    id: "icon-8-4",
  },
  {
    url: EuropaReis_slavenhandel_1,
    id: "icon-8-slavenhandel-1",
  },
  {
    url: EuropaReis_slavenhandel_2,
    id: "icon-8-slavenhandel-2",
  },
  {
    url: EuropaReis_slavenhandel_3,
    id: "icon-8-slavenhandel-3",
  },
  {
    url: EuropaReis_slavenhandel_4,
    id: "icon-8-slavenhandel-4",
  },
];

export const constructIcon = (type, mensenhandel, reden_onvoltooid) => {
  let icon = type;

  if (mensenhandel && reden_onvoltooid) {
    icon = `${type}-slavenhandel-${reden_onvoltooid}`;
  } else if (mensenhandel && !reden_onvoltooid) {
    icon = `${type}-slavenhandel`;
  } else if (!mensenhandel && reden_onvoltooid) {
    icon = `${type}-${reden_onvoltooid}`;
  }

  return icon;
};
