import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { rem } from "polished";
import { useLocation } from "react-router-dom";
import Logo from "../components/Logo";
import LanguageSwitch from "./LanguageSwitch";
import { useTranslation } from "react-i18next";

const StyledNav = styled.nav`
  ${({ theme }) =>
    `
    background-color: ${theme.color.key.white};
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 10;

    ul{
        padding: 0;
        list-style: none;
        display: flex;
        flex-direction: row;
    }
    li{
        flex: 1 0 0%;
        min-width: 0;
    }
    li:first-child{
        display: none;
        max-height:${rem(80)};
    }
    

    @media only screen and (min-width: ${theme.breakPoint}px) {
        width: ${rem(167)};
        min-height: 100vh;
        position: relative;
        padding: ${rem(12)} 0;
        display: flex;
        flex-direction: column;
        ul{
            flex-direction: column;
        }

        li:first-child{
            display: block;
        }

        > div {
          margin-top: auto;
        }
    }
`}
`;

const StyledNavLink = styled(NavLink)`
  ${({ theme }) => `
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.color.key.white};
    font-weight: bold;
    text-transform: uppercase;
    background-color: ${theme.color.globalMenu.dark};
    height: 100%;
    height: ${rem(56)};
    border-right: solid 1px ${theme.color.key.white};


    svg{
        width: 100%;
        height: 100%;
        max-width: ${rem(26)};
    }

    &:hover{
        background-color: ${theme.color.persons.dark};
    }
    &.active {
        background-color: ${theme.color.persons.dark};
    }
    &.logo{
      padding: 0 0 ${rem(32)};
      background-color: ${theme.color.key.white};
      border-bottom: 0;

      svg{
        width: ${rem(120)};
        height: auto;
        max-width: none;
      }
    }
  
    @media only screen and (min-width: ${theme.breakPoint}px) {
        height: 100%;
        padding: ${rem(12)} ${rem(32)};
        border-right: unset;
        border-bottom: solid 1px ${theme.color.key.white};
    }
`}
`;

const Nav = () => {
  let { pathname } = useLocation();
  const { t } = useTranslation();

  if (pathname === "/404") {
    return null;
  }

  return (
    <StyledNav>
      <ul>
        <li>
          <StyledNavLink to="/" className={"logo"}>
            <Logo />
          </StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/map">{t("menu.map")}</StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/ships">{t("menu.ships")}</StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/people">{t("menu.persons")}</StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/explain">{t("menu.explanation")}</StyledNavLink>
        </li>
        <li>
          <StyledNavLink to="/blog">{t("menu.blog")}</StyledNavLink>
        </li>
      </ul>
      <LanguageSwitch />
    </StyledNav>
  );
};

export default Nav;
