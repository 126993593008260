import { useRef, useState, useEffect } from "react";
import { useState as useGlobalState, useDispatch } from "../hooks/useReducer";
import { useSize } from "../hooks/useSize";
import { useNavigate, useLocation } from "react-router-dom";

import styled from "styled-components";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

import { icons } from "../utils/icons";
// import { buildMapLines, buildMapPoints, mapReis } from "../utils/routes";

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_API;

const StyledMap = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #cbd2d3;
  transition: opacity ease 0.3s;
`;

const Map = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const {
    time,
    mapDetailPanel,
    zoom,
    map_center,
    map_filters_active,
    minZoom,
    maxZoom,
    // reizen,
    // map_routes,
    // map_locations,
    points_0,
    points_1,
    points_2,
    points_3,
    points_4,
    points_5,
    points_6,
    points_7,
  } = useGlobalState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const size = useSize(mapContainer);
  const [currentAPoint, setCurrentAPoint] = useState(null);
  const userLocation = useLocation();

  useEffect(() => {
    if (map.current !== null) {
      map.current.setZoom(zoom);
    }
  }, [zoom]);

  useEffect(() => {
    if (map.current !== null) {
      map.current.resize();
      if (
        mapDetailPanel.open &&
        !!currentAPoint &&
        !!currentAPoint?.geometry?.coordinates
      ) {
        map.current.flyTo({
          center: [
            currentAPoint.geometry.coordinates[0],
            currentAPoint.geometry.coordinates[1],
          ],
        });
      }
    }
  }, [size, mapDetailPanel, currentAPoint]);

  useEffect(() => {
    if (map.current !== null) {
      dispatch({ type: "PAUSE_TIME" });
      if (!mapDetailPanel.open) {
        setCurrentAPoint(null);
      }
    }
  }, [mapDetailPanel, dispatch]);

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      fadeDuration: 0,
      container: mapContainer.current,
      projection: "mercator",
      // style: "mapbox://styles/mapbox/light-v10", // default debug style
      // style: "mapbox://styles/dpi-design/cle43bvql001b01qm8mwc1inx",
      style: "mapbox://styles/dpi-design/clhrm338r020o01pg9wzq06ve",
      zoom: zoom,
      center: map_center,
      minZoom: minZoom,
      maxZoom: maxZoom,
    });

    map.current.on("load", () => {
      Promise.all(
        icons.map(
          (icon) =>
            new Promise((resolve, reject) => {
              map.current.loadImage(icon.url, function (error, res) {
                if (error) throw error;
                map.current.addImage(icon.id, res);
                resolve();
              });
            })
        )
      );

      // Provide source from mapbox studio.
      map.current.addSource("mcc-points", {
        type: "vector",
        url: "mapbox://dpi-design.za-journeys",
      });

      // Create (empty) source on map.
      map.current.addSource("points", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      });

      // Add lines layer from mapbox studio.
      map.current.addLayer({
        id: "aline",
        source: "mcc-points",
        "source-layer": "lijnen",
        type: "line",
        minzoom: minZoom,
        maxzoom: maxZoom,
        tolerance: 3.5,
        paint: {
          "line-color": ["get", "color"],
          "line-width": 4,
          "line-opacity": 0.24,
        },
      });

      // Add points layer from imported json.
      map.current.addLayer({
        id: "apoint",
        source: "points",
        type: "symbol",
        minzoom: minZoom,
        maxzoom: maxZoom,
        layout: {
          "icon-image": ["concat", "icon-", ["get", "icon"]],
          "icon-size": 0.75,
          "text-allow-overlap": true,
          "text-ignore-placement": true,
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
        },
      });

      // Navigate to clicked point
      map.current.on("click", "apoint", (e) => {
        const reisID = e.features[0].properties.journey_id;
        if (!!reisID) {
          navigate(reisID, { state: { voyage: reisID } });
        }
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      map.current.on("mouseenter", "apoint", () => {
        map.current.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      map.current.on("mouseleave", "apoint", () => {
        map.current.getCanvas().style.cursor = "";
      });

      map.current.on("moveend", () => {
        if (!map.current.getZoom() < 1 || !map.current.getZoom() > 14) {
          dispatch({ type: "MAP_ZOOM", payload: map.current.getZoom() });
        }
        dispatch({ type: "MAP_CENTER", payload: map.current.getCenter() });
      });

      map.current.resize();
      setLoaded(true);
    });
  }, [dispatch, map_center, maxZoom, minZoom, zoom]);

  // activate to build map points, and lines
  // useEffect(() => {
  //   if (loaded) {
  //     // console.log(reizen.slice(2, 3));

  //     const result = reizen.slice(2, 3).map((reis) => {
  //       return mapReis(reis, map_routes, map_locations);
  //     });

  //     // const points = buildMapPoints(result);
  //     // console.log("points 0: ", points[0]);
  //     // console.log("points 1: ", points[1]);
  //     // console.log("points 2: ", points[2]);
  //     // console.log("points 3: ", points[3]);
  //     // console.log("points 4: ", points[4]);
  //     // console.log("points 5: ", points[5]);
  //     // console.log("points 6: ", points[6]);
  //     // console.log("points 7: ", points[7]);

  //     const lines = buildMapLines(result);
  //     console.log("lines: ", lines[0]);
  //   }
  // }, [loaded, reizen, map_routes, map_locations]);

  useEffect(() => {
    if (userLocation.state !== null) return;
    dispatch({ type: "PLAY_TIME" });
  }, [loaded, userLocation]);

  useEffect(() => {
    if (loaded) {
      if (time >= 0 && time <= 0.124975) {
        map.current.getSource("points").setData(points_0[time]);
      } else if (time >= 0.125 && time <= 0.249975) {
        map.current.getSource("points").setData(points_1[time]);
      } else if (time >= 0.25 && time <= 0.374975) {
        map.current.getSource("points").setData(points_2[time]);
      } else if (time >= 0.375 && time <= 0.499975) {
        map.current.getSource("points").setData(points_3[time]);
      } else if (time >= 0.5 && time <= 0.624975) {
        map.current.getSource("points").setData(points_4[time]);
      } else if (time >= 0.625 && time <= 0.749975) {
        map.current.getSource("points").setData(points_5[time]);
      } else if (time >= 0.75 && time <= 0.874975) {
        map.current.getSource("points").setData(points_6[time]);
      } else if (time >= 0.875 && time <= 1) {
        map.current.getSource("points").setData(points_7[time]);
      }

      map.current.setFilter("aline", [
        "all",
        ["<=", ["number", ["get", "start"]], time],
        [">=", ["number", ["get", "end"]], time],
        ["in", ["get", "type"], map_filters_active.reis_types.join(",")],
      ]);

      map.current.setFilter("apoint", [
        "all",
        ["in", ["get", "type"], map_filters_active.reis_types.join(",")],
      ]);

      if (
        userLocation?.state?.voyage !== null &&
        userLocation?.state?.voyage !== undefined
      ) {
        const currentPoint = map.current
          .getSource("points")
          ._data.features.filter(
            (feature) =>
              feature.properties.journey_id === userLocation.state.voyage
          );
        setCurrentAPoint(currentPoint[0]);
      }
    }
  }, [
    time,
    map_filters_active,
    userLocation?.state?.voyage,
    loaded,
    points_0,
    points_1,
    points_2,
    points_3,
    points_4,
    points_5,
    points_6,
    points_7,
  ]);

  return <StyledMap ref={mapContainer} style={{ opacity: loaded ? 1 : 0 }} />;
};

export default Map;
