import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { rem } from "polished";
import customXLogo from "../assets/x-logo.svg";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  EmailIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";

const StyledSharedTitle = styled.h3`
  ${({ theme }) => `
  margin-bottom: ${rem(8)};
  `}
`;

const StyledWrapper = styled.div`
  ${({ theme }) => `
  margin-bottom: ${rem(8)};
  margin-top: ${rem(40)};
  margin-left: ${rem(8)};
  `}
`;

const CustomTwitterIcon = styled(TwitterIcon)`
  background-image: url(${customXLogo});
  background-size: cover;
  margin: ${rem(10)};
`;

const SharePage = ({ className, url }) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper className={className}>
      <StyledSharedTitle>{t("blogDetail.delen")}</StyledSharedTitle>
      <EmailShareButton url={url}>
        <EmailIcon
          size={40}
          iconFillColor={"black"}
          bgStyle={{ fill: "none" }}
        ></EmailIcon>
      </EmailShareButton>
      <FacebookShareButton url={url}>
        <FacebookIcon
          size={40}
          iconFillColor={"black"}
          bgStyle={{ fill: "none" }}
        ></FacebookIcon>
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <CustomTwitterIcon
          size={20}
          iconFillColor={"none"}
          bgStyle={{ fill: "none" }}
        ></CustomTwitterIcon>
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon
          size={40}
          iconFillColor={"black"}
          bgStyle={{ fill: "none" }}
        ></LinkedinIcon>
      </LinkedinShareButton>
      <WhatsappShareButton url={url}>
        <WhatsappIcon
          size={40}
          iconFillColor={"black"}
          bgStyle={{ fill: "none" }}
        ></WhatsappIcon>
      </WhatsappShareButton>
    </StyledWrapper>
  );
};

export default SharePage;
