import { useEffect, useRef } from "react";
import styled from "styled-components";
import { rem } from "polished";
import TheMap from "../components/Map";
import MapHeader from "../components/MapHeader";
import { useState as useGlobalState, useDispatch } from "../hooks/useReducer";
import { useState } from "react";
import FilterPanel from "../components/FilterPanel";
import RoundButton from "../components/RoundButton";
import Timeline from "../components/Timeline";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { journeyDetail, getArchiveItemsById, getBlogItemsById } from "../api";
import MapDetailHeader from "../components/MapDetailHeader";
import MapDetailBody from "../components/MapDetailBody";
import { useTranslation } from "react-i18next";
import { getTranslatedReisType } from "../utils/helperFunctions";
import { useMatomo } from '@datapunt/matomo-tracker-react';

const StyledPanel = styled.section`
  ${({ theme, $filter, $detail }) => `
    position: fixed;
    top: 0;
    bottom: ${rem(56)};
    left: 0;
    width: 100%;

    @media only screen and (min-width: ${theme.breakPoint}px) {
        bottom: 0;
        width: calc(100% - ${rem(167)});
        left: ${$filter ? rem(-273) : $detail ? rem(692) : rem(167)};
        transition: all ${theme.transition.timing} ${theme.transition.function};
    }
    `}
`;

const StyledDetailPanel = styled.div`
  ${({ theme, $open }) => `
  background-color: ${theme.color.key.light};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: ${$open ? "0" : "100%"};
  transition: right 0.35s ease;
  z-index: 10;
  overflow-y: auto;
  @media only screen and (min-width: ${theme.breakPoint}px) {
    transition: none;
    ${
      $open
        ? `
        right: 100%;
        width: 525px;
        `
        : ``
    }
}
  `}
`;

const StyledRoundButton = styled(RoundButton)`
  ${({ theme, $hasLinks }) => `
  position: absolute;
  right: ${rem(16)};
  top:  ${$hasLinks ? rem(79) : rem(62)};
  @media only screen and (min-width: ${theme.breakPoint}px) {
    top:  ${$hasLinks ? rem(140) : rem(118)};
  }
`}
`;

const MapContainer = styled.div`
  ${({ theme, $detail }) => `
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 0;
        @media only screen and (min-width: ${theme.breakPoint}px) {
          transition: all ${theme.transition.timing} ${
    theme.transition.function
  };
            bottom: 0;
            left: 0;
            right: ${$detail ? rem(525) : 0};
            top: 0;
        }
    `}
`;

const ButtonPanel = styled.aside`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(112)} ${rem(24)} 0 0;
`;

const TimelineButtonPanel = styled.aside`
  ${({ theme }) => `
    position: absolute;
    bottom: ${rem(96)};
    right:0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: ${rem(24)} ${rem(24)} 0 0;
    @media only screen and (min-width: ${theme.breakPoint}px) {
      bottom: ${rem(104)};
      flex-direction: row;
      align-items: center;
    }

    button{
      margin-bottom: ${rem(4)};
      &:last-child {
        margin-top: ${rem(8)};
      }

      @media only screen and (min-width: ${theme.breakPoint}px) {
        margin-bottom: 0;
        margin-right: ${rem(4)};

        &:last-child {
          margin-top: 0;
          margin-right: 0;
          margin-left: ${rem(8)};
        }
      }
     
    }
  `}
`;

const Map = () => {
  const { i18n } = useTranslation();
  const { filterPanel, mapDetailPanel, reis_types, playing, zoom } =
    useGlobalState();
  const dispatch = useDispatch();
  const { voyageId } = useParams();
  const ref = useRef();
  const navigate = useNavigate();
  const [mapDetailData, setMapDetailData] = useState(null);
  const [archiveItems, setArchiveItems] = useState(null);
  const [color, setColor] = useState(null);
  const [blogItems, setBlogItems] = useState(null);
  const { data, error } = useQuery(journeyDetail(voyageId), {
    skip: voyageId === undefined,
  });
  const [zoomState, setZoomState] = useState(zoom);
  const { trackPageView, trackEvent } = useMatomo()

  // Track page view
  useEffect(() => {
    trackPageView({
          documentTitle: 'Map', // optional
        }
    );
  }, [])

  useEffect(() => {
    if (voyageId === undefined) {
      dispatch({ type: "CLOSE_MAP_DETAIL_PANEL" });
    } else {
      dispatch({ type: "OPEN_MAP_DETAIL_PANEL", payload: voyageId });

      getArchiveItemsById(voyageId).then((data) => {
        if (data.data !== undefined || data.error !== undefined) {
          if (data.error) {
            dispatch({ type: "SET_ERROR" });
          } else {
            setArchiveItems(data.data);
          }
        }
      });

      if (i18n.language !== null) {
        getBlogItemsById(voyageId, i18n.language).then((data) => {
          if (data.data !== undefined || data.error !== undefined) {
            if (data.error) {
              dispatch({ type: "SET_ERROR" });
            } else {
              setBlogItems(data.data);
            }
          }
        });
      }
    }
  }, [voyageId, i18n.language, dispatch]);

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      if (error) {
        dispatch({ type: "SET_ERROR" });
      } else {
        if (data.reizen_by_id !== null) {
          setMapDetailData(data.reizen_by_id);
          document.addEventListener("mousedown", handleDocumentClick);
        } else {
          document.removeEventListener("mousedown", handleDocumentClick);
        }
      }

      return () => {
        document.removeEventListener("mousedown", handleDocumentClick);
      };
    }
  }, [data, error, dispatch]);

  useEffect(() => {
    if (zoomState !== null) {
      dispatch({ type: "MAP_ZOOM", payload: zoomState });
    }
  }, [zoomState, dispatch]);

  useEffect(() => {
    if (mapDetailData !== null) {
      const result = reis_types.find((x) => x.naam === mapDetailData.type.naam);
      if (result !== null && result?.color) {
        setColor(result.color);
      }
    }
  }, [mapDetailData]);

  const handleOpenFilter = () => {
    if (!filterPanel) {
      dispatch({ type: "OPEN_FILTER_PANEL" });
    }
  };

  const handlePlayPause = () => {
    if (playing === "play") {
      dispatch({ type: "PAUSE_TIME" });
    } else {
      dispatch({ type: "PLAY_TIME" });
    }
  };

  const handleCloseDetail = () => {
    setMapDetailData(null);
    navigate("/map");
  };

  const handleDocumentClick = (event) => {
    if (ref && !ref.current.contains(event.target)) {
      handleCloseDetail();
    }
  };

  const handleZoomIn = () => {
    if (zoom + 0.25 >= 15) return; // maybe fix this with maxZoom on mapbox
    setZoomState(zoom + 0.25);
  };

  const handleZoomOut = () => {
    if (zoom - 0.25 <= 1) return; // maybe fix this with minZoom on mapbox
    setZoomState(zoom - 0.25);
  };

  return (
    <StyledPanel
      $filter={filterPanel}
      $detail={!filterPanel && mapDetailPanel.open}
    >
      {mapDetailData && archiveItems && (
        <StyledDetailPanel $open={mapDetailData} ref={ref}>
          <StyledRoundButton
            id="close"
            onClick={handleCloseDetail}
            $hasLinks={
              archiveItems.filter(
                (item) =>
                  item.is_overview ||
                  (mapDetailData.type.naam === "Driehoeksreis" &&
                    item.is_slavevoyage)
              ).length > 0
            }
          />
          <MapDetailHeader
            color={color}
            voyageNumber={mapDetailData?.reisnummer}
            voyageType={getTranslatedReisType(
              mapDetailData.type.naam,
              i18n.language
            )}
            voyageTitle={mapDetailData.schip.naam}
            voyageCancelled={mapDetailData.reden_onvoltooid}
            voyageShipID={mapDetailData.schip.id}
            links={archiveItems.filter(
              (item) =>
                item.is_overview ||
                (mapDetailData.type.naam === "Driehoeksreis" &&
                  item.is_slavevoyage)
            )}
          />
          <MapDetailBody
            color={color}
            data={mapDetailData}
            archiveItems={archiveItems.filter((item) => !item.is_slavevoyage)}
            blogItems={blogItems}
          />
        </StyledDetailPanel>
      )}

      <MapContainer $detail={!filterPanel && mapDetailPanel.open}>
        <TheMap />
        <MapHeader />
        <ButtonPanel>
          <RoundButton
            id="filter"
            style={{ marginBottom: rem(16) }}
            onClick={handleOpenFilter}
          />
          <RoundButton
            id="plus"
            style={{ marginBottom: rem(8) }}
            onClick={handleZoomIn}
          />
          <RoundButton id="min" onClick={handleZoomOut} />
        </ButtonPanel>
        <TimelineButtonPanel>
          <RoundButton
            onClick={handlePlayPause}
            id={playing === "play" ? "pause" : "play"}
          />
        </TimelineButtonPanel>
        <Timeline />
      </MapContainer>
      <FilterPanel />
    </StyledPanel>
  );
};

export default Map;
