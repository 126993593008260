import styled from "styled-components";
import { rem } from "polished";
import Icon from "./Icons";
import fontParser from "../utils/fontParser";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getOrdinal } from "../utils/helperFunctions";

const StyledPanel = styled.div`
  ${({ theme, $color }) => `
  padding: ${rem(22)} ${rem(16)};
  background-color: ${
    $color ? theme.color.travel[$color].primary : theme.color.key.light
  };
  color: ${theme.color.key.white};
  margin: ${rem(16)} ${rem(16)} 0 ${rem(16)};
  `}
`;

const StyledInnerPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

const StyledIcon = styled(Icon)`
  ${({ theme }) => `
  width: ${rem(40)};
  height: ${rem(40)};
  border-radius: ${rem(20)};
  margin-right: ${rem(12)};
  
  @media only screen and (min-width: ${theme.breakPoint}px) {
    width: ${rem(48)};
    height: ${rem(48)};
    border-radius: ${rem(24)};
  }
 `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${rem(12)};
`;

const VoyageMetaWrapper = styled.div`
  display: flex;
`;

const VoyageNumber = styled.h5`
   {
    ${fontParser("caption1")}
    font-weight: normal;
  }
`;

const VoyageType = styled.h5`
${fontParser("caption1")}

  margin: 0 ${rem(4)};
  font-weight: normal;
}
`;

const VoyageCanceled = styled.h5`
  ${fontParser("caption1")}
  font-weight: normal;
}
`;

const VoyageTitle = styled.h2`
  ${fontParser("title1")}
  margin-bottom: ${rem(4)};
  cursor: pointer;
`;

export const StyledArchiveIcon = styled(Icon)`
  ${({ theme }) => `
  width: ${rem(8)};
  margin-right: ${rem(4)};

  @media only screen and (min-width: ${theme.breakPoint}px) {
    width: ${rem(12)};
    margin-right: ${rem(8)};
  }
  `}
`;

export const StyledLink = styled.a`
  ${({ theme }) => `
  color: ${theme.color.key.white};
  display: flex;
  margin-right: ${rem(12)};
  font-size: ${rem(10)};
  line-height: ${rem(16)};
  font-weight: 700;
  font-family: 'PT Serif';
  &:last-child {
      margin-right: 0;
  }

  @media only screen and (min-width: ${theme.breakPoint}px) {
    font-size: ${rem(16)};
    line-height: ${rem(24)};
  }
  `}
`;

export const StyledLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MapDetailHeader = ({
  voyageNumber = "",
  voyageType,
  voyageTitle,
  voyageCancelled,
  voyageShipID,
  links = [],
  color,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleNavigateToShip = (id) => {
    navigate(`/ships/${id}`);
  };

  return (
    <StyledPanel $color={color}>
      <StyledInnerPanel>
        <StyledIcon id="TravelHeader" />
        <Content>
          <VoyageMetaWrapper>
            {voyageNumber !== "" &&
              voyageNumber !== "geannuleerd" &&
              voyageNumber !== "verhuurd" && (
                <VoyageNumber>
                  {getOrdinal(voyageNumber, i18n.language)}{" "}
                  {t("mapDetailPanel.voyage")}
                  {": "}
                </VoyageNumber>
              )}
            <VoyageType>{voyageType}</VoyageType>
            {voyageCancelled !== null && (
              <VoyageCanceled>{voyageCancelled.naam}</VoyageCanceled>
            )}
          </VoyageMetaWrapper>
          <VoyageTitle onClick={() => handleNavigateToShip(voyageShipID)}>
            {voyageTitle}
          </VoyageTitle>
          <StyledLinks>
            {links &&
              links.length > 0 &&
              links.map(({ link, title }, id) => (
                <StyledLink
                  key={id}
                  href={link !== null ? link : ""}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <StyledArchiveIcon id="Link" />
                  {title}
                </StyledLink>
              ))}
          </StyledLinks>
        </Content>
      </StyledInnerPanel>
    </StyledPanel>
  );
};

export default MapDetailHeader;
