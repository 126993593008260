import styled from "styled-components";
import { rem } from "polished";
import Cards from "./Cards";
import CardVoyageListItem from "./CardVoyageListItem";
import CardBodyContent from "./CardBodyContent";
import { StyledRow, StyledColumn, StyledValue } from "./CardBodyContent";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Icon from "./Icons";
import { formatDate } from "../utils/helperFunctions";
import { getTranslatedRole } from "../utils/helperFunctions";

const StyledDetailBody = styled.div`
  ${({ theme }) => `
    height: 100%;
    color: ${theme.color.key.dark};
    background-color: ${theme.color.key.light};
    display: flex;
    flex-direction: column;
    padding: ${rem(16)};
    margin: 0 ${rem(16)} ${rem(16)} ${rem(16)};

    @media only screen and (min-width: ${theme.breakPoint}px) {
      padding: 0;
      background: ${theme.color.key.white};
   }
`}
`;

const StyledCard = styled(Cards)`
  ${({ theme }) => `
    // margin-bottom: ${rem(16)};
    header {
      background: ${theme.color.persons.light};
    }
  @media only screen and (min-width: ${theme.breakPoint}px) {
    border-top: 0;

}
`}
`;

const StyledLink = styled(Link)`
  ${({ theme }) => `
  color: ${theme.color.key.dark};
  display: flex;
  align-items: center;
  margin-bottom: ${rem(4)};

  svg {
    height: ${rem(12)};
    width: ${rem(12)};
    flex-shrink: 0;
  }
  `}
`;

const StyledLinkContent = styled.span`
  margin-left: ${rem(4)};
  flex-shrink: 0;
`;

const StyledDescriptionContent = styled.div`
  margin-bottom: ${rem(16)};
`;

const StyledLabel = styled.span`
  ${({ theme }) =>
    `
    color: ${theme.color.persons.dark};
    font-weight: normal;
    text-transform: uppercase;
    font-size: 0.875rem;
    line-height: 1.125rem;
`}
`;

const DetailBody = ({ data, voyageData, archiveItems, blogItems }) => {
  const { t, i18n } = useTranslation();
  const languageID = i18n.language === "nl" ? "nl-NL" : "en-US";
  const translations = data.translations.find(
    (translation) => translation.languages_code.code === languageID
  );
  var filteredRole = Array.from(
    new Set(data.rollen.map((item) => item.rollen_id.rol))
  ).map((rol) => data.rollen.find((item) => item.rollen_id.rol === rol));
  if(filteredRole[0].rollen_id.rol=='Kapitein'){
    filteredRole=filteredRole.reverse();
  }
  return (
    <StyledDetailBody>
      {filteredRole && (
        <StyledCard title={t("peopleDetail.career")}>
          <CardBodyContent>
            {filteredRole.map((rol, index) => {
              return (
                <StyledRow key={index}>
                  <StyledColumn $col={12}>
                    <StyledLabel>
                      {t("peopleDetail.role")} {index + 1}
                    </StyledLabel>
                    <StyledValue>
                      {getTranslatedRole(rol.rollen_id.rol, i18n.language)}
                    </StyledValue>
                  </StyledColumn>
                  <StyledColumn $col={12}>
                    {!!rol.van && !!rol.tot && (
                      <StyledLabel>
                        {t("peopleDetail.periodRole")} {index + 1}
                      </StyledLabel>
                    )}
                    {!!rol.van && (
                      <StyledValue>
                        {formatDate(rol.van, i18n.language)}
                        {" -"}
                      </StyledValue>
                    )}
                    {!!rol.tot && (
                      <StyledValue>
                        {formatDate(rol.tot, i18n.language)}
                      </StyledValue>
                    )}
                  </StyledColumn>
                </StyledRow>
              );
            })}
          </CardBodyContent>
        </StyledCard>
      )}
      {data.echtgenote && (
        <StyledCard title={t("peopleDetail.spouse")}>
          <CardBodyContent>
            {data.echtgenote.split(",").map((wife, index) => (
              <StyledColumn key={index}>
                <StyledLabel>{index + 1}</StyledLabel>
                <StyledValue>{wife}</StyledValue>
              </StyledColumn>
            ))}
          </CardBodyContent>
        </StyledCard>
      )}
      {voyageData && voyageData.length > 0 && (
        <StyledCard
          title={"Reizen"}
          icon={"Destination"}
          category="destination"
        >
          {voyageData.map((element, id) => {
            return (
              <CardVoyageListItem
                key={id}
                data={element}
                position={id + 1}
                page={"overview"}
              />
            );
          })}
        </StyledCard>
      )}
      {archiveItems && archiveItems.length > 0 && (
        <StyledCard title={t("peopleDetail.archiveTitle")}>
          <CardBodyContent>
            {archiveItems.map((item, id) => {
              return (
                <StyledColumn $col={12}>
                  <StyledValue key={id}>
                    <StyledLink
                      to={item.link !== null ? item.link : ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon id="Link" />
                      <StyledLinkContent>{item.title}</StyledLinkContent>
                    </StyledLink>
                  </StyledValue>
                </StyledColumn>
              );
            })}
          </CardBodyContent>
        </StyledCard>
      )}
      {blogItems && blogItems.length > 0 && (
        <StyledCard title={t("peopleDetail.blogTitle")}>
          <CardBodyContent>
            {blogItems.map((item, id) => {
              return (
                <StyledColumn $col={12}>
                  <StyledValue key={id}>
                    <StyledLink to={`/blog/${item.translations[0].slug}`}>
                      <Icon id="Link" />
                      <StyledLinkContent>
                        {item.translations[0].title}
                      </StyledLinkContent>
                    </StyledLink>
                  </StyledValue>
                </StyledColumn>
              );
            })}
          </CardBodyContent>
        </StyledCard>
      )}
      {translations && translations?.beschrijving_vertaling && (
        <StyledCard title={t("peopleDetail.description")}>
          <CardBodyContent>
            <StyledDescriptionContent>
              {translations.beschrijving_vertaling}
            </StyledDescriptionContent>
          </CardBodyContent>
        </StyledCard>
      )}
    </StyledDetailBody>
  );
};

export default DetailBody;
