import reisTypeColor from "./utils/reisColorMapper";
import { calcSpeed } from "./utils/helperFunctions";
import { isMobile } from "react-device-detect";

export const initialStore = {
  data: null,
  bemannings_rollen: [],
  map_routes: [],
  map_locations: [],
  reizen: [],
  voyages: [],
  points_0: {},
  points_1: {},
  points_2: {},
  points_3: {},
  points_4: {},
  points_5: {},
  points_6: {},
  points_7: {},
  reis_types: [],
  schip_types: [],
  schip_type: null,
  filterPanel: false,
  map_filters_active: {
    reis_types: [1, 2, 3, 4, 5, 6, 7, 8],
  },
  ships: [],
  people: [],
  helpPages: [],
  helpPagesNavList: [],
  blogs: [],
  blogActiveFilter: 0,
  blogFilterList: [
    {
      id: 0,
      title: "all",
    },
    {
      id: 5,
      title: "slaafgemaakten",
    },
    {
      id: 6,
      title: "bestemmingen",
    },
    {
      id: 4,
      title: "goederen",
    },
    {
      id: 5,
      title: "aanBoord",
    },
    {
      id: 6,
      title: "middelburg",
    },
    {
      id: 7,
      title: "papieren",
    },
  ],
  error: false,
  mapDetailPanel: {
    open: false,
    content: null,
  },
  time: 0.012575,
  playing: "pause", // enum: pause play resume
  speed: calcSpeed(0.5),
  zoom: isMobile ? 1.75 : 2.5,
  minZoom: isMobile ? 1.75 : 2,
  maxZoom: isMobile ? 2.75 : 11,
  map_center: { lng: -36.5625, lat: 33.431441335575286 },
};

export const reducer = (state, action) => {
  switch (action.type) {
    /*
     * Initial data load
     */
    case "SET_INITIAL_DATA":
      return { ...state, data: action.payload };

    case "SET_POINTS_0":
      return { ...state, points_0: action.payload };
    case "SET_POINTS_1":
      return { ...state, points_1: action.payload };
    case "SET_POINTS_2":
      return { ...state, points_2: action.payload };
    case "SET_POINTS_3":
      return { ...state, points_3: action.payload };
    case "SET_POINTS_4":
      return { ...state, points_4: action.payload };
    case "SET_POINTS_5":
      return { ...state, points_5: action.payload };
    case "SET_POINTS_6":
      return { ...state, points_6: action.payload };
    case "SET_POINTS_7":
      return { ...state, points_7: action.payload };
    /*
     * Set bemannings rollen
     */
    case "SET_BEMANNINGS_ROLLEN":
      return { ...state, bemannings_rollen: action.payload };
    /*
     * Set map routes
     */
    case "SET_MAP_ROUTES":
      return { ...state, map_routes: action.payload };
    /*
     * Set map locations
     */
    case "SET_MAP_LOCATIONS":
      return { ...state, map_locations: action.payload };
    /*
     * Set map filters
     */
    case "SET_ACTICE_MAP_FILTERS":
      return { ...state, map_filters_active: action.payload };
    /*
    /*
     * Set reizen
     */
    case "SET_REIZEN":
      return { ...state, reizen: action.payload };
    /*
     * Set schip types
     */
    case "SET_SCHIP_TYPES":
      return { ...state, schip_types: action.payload };
    /*
     * Set schip type filter
     */
    case "SET_SCHIP_TYPE":
      return { ...state, schip_type: action.payload };
    /*
     * Set reis types
     */
    case "SET_REIS_TYPES":
      return {
        ...state,
        reis_types: action.payload.map((reis) => {
          return { ...reis, color: reisTypeColor(reis.id) };
        }),
      };
    /*
     * Set initial ship list
     */
    case "SET_SHIP_LIST":
      return { ...state, ships: action.payload };
    /*
     * Set initial person list
     */
    case "SET_PEOPLE_LIST":
      return { ...state, people: action.payload };
    /*
     * Set initial blog list
     */
    case "SET_BLOG_LIST":
      return { ...state, blogs: action.payload };
    /*
     * Set initial blog filter
     */
    case "SET_BLOG_FILTER":
      return { ...state, blogActiveFilter: action.payload };
    /*
     * Set initial blog filter list
     */
    case "SET_BLOG_FILTER_LIST":
      return { ...state, blogFilterList: action.payload };
    /*
     * Set initial help pages list
     */
    case "SET_HELP_PAGES_LIST":
      return { ...state, helpPages: action.payload };
    /*
     * Set initial help pages filter list
     */
    case "SET_HELP_PAGES_NAV_LIST":
      return { ...state, helpPagesNavList: action.payload };
    /*
     * Open or close the filter panel
     */
    case "OPEN_FILTER_PANEL":
      return { ...state, filterPanel: true };
    case "CLOSE_FILTER_PANEL":
      return { ...state, filterPanel: false };
    /*
     * Open or close the map detail panel
     */
    case "OPEN_MAP_DETAIL_PANEL":
      return {
        ...state,
        mapDetailPanel: { open: true, content: action.payload },
      };
    case "CLOSE_MAP_DETAIL_PANEL":
      return {
        ...state,
        mapDetailPanel: { ...state.mapDetailPanel, open: false },
      };
    /*
     * Update time value
     */
    case "UPDATE_TIME":
      return {
        ...state,
        time: Math.round(action.payload * 40000) / 40000,
      };
    /*
     * Update time player
     */
    case "PLAY_TIME":
      return {
        ...state,
        playing: "play",
      };
    case "PAUSE_TIME":
      return {
        ...state,
        playing: "pause",
      };
    case "INTERVAL_TIME":
      const futureTime = state.time + state.speed;
      if (futureTime > 1) {
        return { ...state, time: 1, playing: "pause" };
      }

      return {
        ...state,
        time: Math.round(futureTime * 40000) / 40000,
      };

    /*
     * Zoom map
     */
    case "MAP_ZOOM":
      return {
        ...state,
        zoom: action.payload,
      };

    /*
     * Zoom center
     */
    case "MAP_CENTER":
      return {
        ...state,
        map_center: action.payload,
      };
    /*
     * Initial error handling
     */
    case "SET_ERROR":
      return { ...state, error: true };
    default:
      return state;
  }
};
