import styled from "styled-components";
import { rem } from "polished";
import fontParser from "../utils/fontParser";

const StyledCard = styled.article`
  ${({ theme }) =>
    `
`}
`;

const StyledCardHeader = styled.header`
  ${({ theme }) =>
    `
    background-color: ${theme.color.ships.light};
    color: ${theme.color.key.dark};
    display: flex;
    padding: ${rem(8)} ${rem(16)};
`}
`;

const StyledTitle = styled.h3`
  align-self: center;
  color: inherit;
  font-weight: 700;
  text-transform: uppercase;
`;

const StyledCardBody = styled.div`
  ${({ theme }) =>
    `
    background-color: ${theme.color.key.white};
`}
`;

const Card = ({
  title = "",
  category = "",
  children = null,
  className = "",
}) => {
  return (
    <StyledCard className={`${className} ${category}`}>
      <StyledCardHeader>
        <StyledTitle>{title}</StyledTitle>
      </StyledCardHeader>
      <StyledCardBody>{children}</StyledCardBody>
    </StyledCard>
  );
};

export default Card;
