import styled from "styled-components";
import { rem } from "polished";
import fontParser from "../utils/fontParser";
import Icon from "./Icons";
import { Link } from "react-router-dom";
import { StyledEnslavedIcon } from "./OverviewListShipItem";
import { useTranslation } from "react-i18next";
import { getTranslatedRole } from "../utils/helperFunctions";

const typePersoonToString = (rollen) => {
  return rollen.reduce((acc, item, index) => {
    if (index === 0) {
      return item.rollen_id.rol;
    } else {
      return acc + `, ${item.rollen_id.rol}`;
    }
  }, "");
};

export const StyledListItemContainer = styled.li`
  ${({ theme }) =>
    `
    list-style: none;
    flex: 0 0 auto;
    width: 100%;
    padding: 0 ${rem(12)};
    margin-bottom: ${rem(16)};
  
    @media only screen and (min-width: 720px) {
      margin-bottom: ${rem(24)};
      width: 50%;
  }

    @media only screen and (min-width: 900px) {
        margin-bottom: ${rem(24)};
        width: 25%;
    }
`}
`;

export const StyledListItem = styled(Link)`
  ${({ theme, $isDetailOpen, $isSelected }) =>
    `
    width: 100%;
    box-sizing: border-box;
    display: block;
    text-decoration: none;
    color: ${theme.color.key.dark};
    cursor: pointer;
    position: relative;

    ${
      $isDetailOpen
        ? `
        &:after{
          content: '';
          background-color: ${theme.color.key.white};
          opacity: 0.4;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          position: absolute;
          z-index: 1;
        }
        `
        : ``
    }

    ${
      $isSelected
        ? `
         box-shadow: 0 0 0 2px ${theme.color.persons.dark};
         `
        : ``
    }
`}
`;

export const StyledItemHeader = styled.div`
  ${({ theme }) =>
    `
    display: flex;
    align-items: baseline;
    padding: ${rem(12)} 0 0 0;
    background: ${theme.color.key.white};
`}
`;

export const StyledItemHeaderTitle = styled.h3`
  ${({ theme }) =>
    `
  ${fontParser("caption2")};
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:first-child {
    padding: 0 0 0 ${rem(12)};
  }
`}
`;

export const StyledItemNumberHightlighted = styled.div`
  ${({ theme }) => `
    color: ${theme.color.globalMenu.primary};
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 ${rem(8)};
    white-space: nowrap;
`}
`;

export const StyledItemBody = styled.div`
  ${({ theme }) =>
    `
    background-color: ${theme.color.key.white};
    position: relative;
    min-height: ${rem(80)};
    display: flex;
    flex-direction: column;
    padding: 0 ${rem(12)};
`}
`;

export const StyledName = styled.h4`
  ${({ theme }) => `
  ${fontParser("cardName")};
`}
`;

const StyledLegenda = styled.div`
  ${({ theme }) => `
  display: flex;
  margin-top:  ${rem(8)};
`}
`;

const OverviewListItem = ({ item, isDetailOpen, isSelected }) => {
  const { t, i18n } = useTranslation();
  const filteredRole = Array.from(
    new Set(item.rollen.map((item) => item.rollen_id.rol))
  ).map((rol) => item.rollen.find((item) => item.rollen_id.rol === rol));

  return (
    <StyledListItemContainer>
      <StyledListItem
        to={item.id}
        $isDetailOpen={isDetailOpen}
        $isSelected={isSelected}
      >
        <StyledItemHeader>
          {filteredRole.map((rol, index) => {
            let displayedRole = rol.rollen_id.rol;
            if (filteredRole.length > 1 && index === 1) {
              displayedRole = displayedRole.slice(0, 3) + "...";
            }
            return (
              <StyledItemHeaderTitle key={index}>
                {getTranslatedRole(
                  filteredRole.length > 1 && index > 0
                    ? `, ${displayedRole}`
                    : displayedRole,
                  i18n.language
                )}
              </StyledItemHeaderTitle>
            );
          })}

          {item.aantal_schepen !== null && (
            <StyledItemNumberHightlighted>
              {`${item.aantal_schepen} ${
                item.aantal_schepen === 1
                  ? `${t("people.ship")}`
                  : `${t("people.ships")}`
              }`}
            </StyledItemNumberHightlighted>
          )}
        </StyledItemHeader>
        <StyledItemBody>
          <StyledName>{item.naam}</StyledName>
          <StyledLegenda>
            {item.icon_mensenhandel && <StyledEnslavedIcon id={"Enslaved"} />}
          </StyledLegenda>
        </StyledItemBody>
      </StyledListItem>
    </StyledListItemContainer>
  );
};

export default OverviewListItem;
