import { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { rem } from "polished";
import Cards from "./Cards";
import { useTranslation } from "react-i18next";
import CardBodyContent from "./CardBodyContent";
import CardVoyageListItem from "./CardVoyageListItem";
import { Link } from "react-router-dom";
import Icon from "../components/Icons";
import { StyledColumn, StyledValue } from "./CardBodyContent";
import TransparentButton from "./TransparentButton";
import {
  calcDays,
  formatDate,
  getTranslatedArchiveTitle,
  getTranslatedRole,
} from "../utils/helperFunctions";
import fontParser from "../utils/fontParser";
import { filterBestemming, adjustBestemming } from "../utils/routes";

const StyledLabel = styled.div`
  ${({ theme, $color }) => `
    color: ${theme.color.travel[$color]?.primary};
    font-weight: normal;
`}
`;

const StyledDetailBody = styled.div`
  ${({ theme, $color }) => `
    height: 100%;
    color: ${theme.color.key.dark};
    background-color: ${theme.color.key.light};
    display: flex;
    flex-direction: column;
    padding: 0 ${rem(16)};
    header {
      background-color: ${
        $color ? theme.color.travel[$color].light : theme.color.key.light
      };
    }

    @media only screen and (min-width: ${theme.breakPoint}px) {
    background: ${theme.color.key.white};
    padding: 0;
    margin: 0 ${rem(16)} ${rem(16)} ${rem(16)};
   }
`}
`;

const StyledCard = styled(Cards)`
  ${({ theme, $half, $color }) => `
    //margin-bottom: ${rem(16)};
    background-color: ${theme.color.key.white};
    width: 100%;

    @media only screen and (min-width: ${theme.breakPoint}px) {
        width: ${$half ? `50%` : `100%`};  
        border-right-width:  ${$half ? `0` : `${rem(1)}`};

        + article{
          border-right-width: ${rem(1)};
        }
     }
    `}
`;

const LabelContentLarge = styled.span`
  ${({ theme }) => `
  font-style: normal;
  color: ${theme.color.key.dark};
  `}
`;

const StyledLink = styled(Link)`
  ${({ theme }) => `
    color: ${theme.color.key.dark};
    display: flex;
    align-items: center;
    margin-bottom: ${rem(4)};

    svg {
      height: ${rem(12)};
      width: ${rem(12)};
      flex-shrink: 0;
    }
  `}
`;

const StyledLinkContent = styled.span`
  margin-left: ${rem(4)};
  flex-shrink: 0;
`;

const MapDetailBody = ({ data, archiveItems, blogItems, color }) => {
  const { t, i18n } = useTranslation();
  const [personsByRol, setPersonsByRol] = useState([]);
  const [voyageItemOpen, setVoyageItemOpen] = useState(null);

  useEffect(() => {
    const rollen = [];
    data.bemanning.forEach((bemanning) => {
      rollen[bemanning.rollen_id.rol] = [];
      rollen[bemanning.rollen_id.rol].push(bemanning.personen_id);
    });
    setPersonsByRol(rollen);
  }, [data]);

  const bestemmingen = useMemo(() => {
    const sortedBestemmingen = [...data.bestemmingen].sort(
      (a, b) => a.sort - b.sort
    );
    return sortedBestemmingen.map(adjustBestemming).filter(filterBestemming);
  }, [data.bestemmingen]);

  const handleOpenVoyageItem = (id) => {
    if (voyageItemOpen === id) {
      setVoyageItemOpen(null);
    } else {
      setVoyageItemOpen(id);
    }
  };

  return (
    <StyledDetailBody $color={color}>
      {(data.plaats_aanvang_reis ||
        data.plaats_einde_reis ||
        data.datum_aanvang_reis ||
        data.datum_einde_reis) && (
        <StyledCard title={t("mapDetailPanel.travelTimeTitle")}>
          <CardBodyContent>
            <StyledColumn>
              <StyledLabel $color={color}>
                {t("mapDetailPanel.travelTimeStart")}
              </StyledLabel>
              {data.plaats_aanvang_reis.plaats && (
                <StyledValue>{data.plaats_aanvang_reis.plaats}</StyledValue>
              )}
              {data.datum_aanvang_reis && (
                <StyledValue>
                  {formatDate(data.datum_aanvang_reis, i18n.language)}
                </StyledValue>
              )}
            </StyledColumn>
            <StyledColumn>
              <StyledLabel $color={color}>
                {t("mapDetailPanel.travelTimeEnd")}
              </StyledLabel>
              {data.plaats_einde_reis.plaats && (
                <StyledValue>{data.plaats_einde_reis.plaats}</StyledValue>
              )}
              {data.datum_einde_reis && (
                <StyledValue>
                  {formatDate(data.datum_einde_reis, i18n.language)}
                </StyledValue>
              )}
            </StyledColumn>
            {data.datum_aanvang_reis && data.datum_einde_reis && (
              <StyledColumn>
                <StyledLabel $color={color}>
                  {t("mapDetailPanel.travelTimeDuration")}
                </StyledLabel>
                <StyledValue>
                  <LabelContentLarge>
                    {calcDays(data.datum_aanvang_reis, data.datum_einde_reis)}
                  </LabelContentLarge>
                </StyledValue>
              </StyledColumn>
            )}
          </CardBodyContent>
        </StyledCard>
      )}

      {personsByRol && Object.keys(personsByRol).length > 0 && (
        <StyledCard
          title={t("mapDetailPanel.crewCardTitle")}
          icon={"Crew"}
          category="crew"
        >
          <CardBodyContent>
            {Object.keys(personsByRol).map((rol, key) => (
              <StyledColumn key={key}>
                <StyledLabel $color={color}>
                  {getTranslatedRole(rol, i18n.language)}
                </StyledLabel>
                {personsByRol[rol].map(({ naam, id }) => {
                  return (
                    <StyledValue key={id}>
                      <StyledLink to={`/people/${id}`}>{naam}</StyledLink>
                    </StyledValue>
                  );
                })}
              </StyledColumn>
            ))}
            {data.aantal_bemanningsleden_vertrek && (
              <StyledColumn>
                <StyledLabel $color={color}>
                  {t("mapDetailPanel.crewCardCrewStart")}
                </StyledLabel>
                <StyledValue>
                  <LabelContentLarge>
                    {data.aantal_bemanningsleden_vertrek}
                  </LabelContentLarge>
                </StyledValue>
              </StyledColumn>
            )}
          </CardBodyContent>
        </StyledCard>
      )}

      <StyledCard title={t("mapDetailPanel.destinationCardTitle")}>
        {bestemmingen.map((bestemming, key) => {
          return (
            <CardVoyageListItem
              key={key}
              data={bestemming}
              position={key + 1}
              open={voyageItemOpen === key}
              color={color}
              onClick={() => handleOpenVoyageItem(key)}
            >
              <TransparentButton
                id="plus"
                key={key}
                open={voyageItemOpen === key}
                onClick={() => handleOpenVoyageItem(key)}
              />
            </CardVoyageListItem>
          );
        })}
        {data.plaats_einde_reis && (
          <CardVoyageListItem
            data={data}
            last={true}
            lastBestemming={bestemmingen[bestemmingen.length - 1]}
            returnGoods={
              bestemmingen[bestemmingen.length - 1]?.goederen_naar_huis.length >
              0
                ? bestemmingen[bestemmingen.length - 1]?.goederen_naar_huis
                : null
            }
            position={bestemmingen.length + 1}
            open={voyageItemOpen === bestemmingen.length + 1}
            onClick={() => handleOpenVoyageItem(bestemmingen.length + 1)}
            color={color}
          >
            <TransparentButton
              id="plus"
              onClick={() => handleOpenVoyageItem(bestemmingen.length + 1)}
              open={voyageItemOpen === bestemmingen.length + 1}
            />
          </CardVoyageListItem>
        )}
      </StyledCard>

      {data.mensenhandel && (
        <StyledCard title={t("mapDetailPanel.enslavedTotalTitle")}>
          <CardBodyContent>
            <StyledColumn>
              <StyledLabel $color={color}>
                {t("mapDetailPanel.enslavedTotalBought")}
              </StyledLabel>
              <StyledValue>
                {data.aantal_mensen_gekocht || 0} {t("mapDetailPanel.enslaved")}
              </StyledValue>
            </StyledColumn>
            <StyledColumn>
              <StyledLabel $color={color}>
                {t("mapDetailPanel.enslavedTotalSold")}
              </StyledLabel>
              <StyledValue>
                {data.aantal_mensen_verkocht || 0}{" "}
                {t("mapDetailPanel.enslaved")}
              </StyledValue>
            </StyledColumn>
          </CardBodyContent>
        </StyledCard>
      )}
      {archiveItems && archiveItems.length > 0 && (
        <StyledCard title={t("mapDetailPanel.archiveCardTitle")} $color={color}>
          <CardBodyContent>
            {archiveItems.map((item, i) => {
              return (
                <StyledColumn key={i}>
                  <StyledValue>
                    <StyledLink
                      to={item.link !== null ? item.link : ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon id="Link" />
                      <StyledLinkContent>
                        {getTranslatedArchiveTitle(item.title, i18n.language)}
                      </StyledLinkContent>
                    </StyledLink>
                  </StyledValue>
                </StyledColumn>
              );
            })}
          </CardBodyContent>
        </StyledCard>
      )}
      {blogItems && blogItems.length > 0 && (
        <StyledCard
          title={t("mapDetailPanel.blogCardTitle")}
          icon={"Blog"}
          category=""
        >
          <CardBodyContent>
            <StyledColumn>
              <StyledLabel $color={color}>
                {t("mapDetailPanel.blogCardLabel")}
              </StyledLabel>
              {blogItems.map((item, i) => {
                return (
                  <StyledValue key={i}>
                    <StyledLink to={`/blog/${item.translations[0].slug}`}>
                      <Icon id="Link" />
                      <StyledLinkContent>
                        {item.translations[0].title}
                      </StyledLinkContent>
                    </StyledLink>
                  </StyledValue>
                );
              })}
            </StyledColumn>
          </CardBodyContent>
        </StyledCard>
      )}
    </StyledDetailBody>
  );
};

export default MapDetailBody;
