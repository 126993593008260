import { useState, useEffect } from "react";
import { useState as useGlobalState, useDispatch } from "../hooks/useReducer";
import PagePanel from "../components/PagePanel";
import BlogDetailHeader from "../components/BlogDetailHeader";
import SharePage from "../components/SharePage";
import PageContent from "../components/PageContent";
import { getBlogsBySlug, getBlogsByID } from "../api";
import { useParams } from "react-router-dom";
import { StyledList, StyledListItem } from "../components/PageSubNavigation";
import CtaButton from "../components/CtaButton";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { rem } from "polished";
import parse from "html-react-parser";
import fontParser from "../utils/fontParser";
import { useNavigate } from "react-router-dom";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const StyledListDetail = styled(StyledList)`
  display: none;
  ${({ theme }) => `
  @media only screen and (min-width: ${theme.breakPoint}px) {
    display: flex;
  }
`}
`;

const StyledPageContent = styled(PageContent)`
  ${({ theme }) => `
      @media only screen and (min-width: ${theme.breakPoint}px) {
        padding: ${rem(32)};
      }
  `}
`;

const StyledCategoryWrapper = styled.div`
  ${({ theme }) =>
    `
  color: ${theme.color.globalMenu.primary};
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 0 ${rem(16)};
`}
`;

const StyledCategory = styled.span`
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  &:after {
    margin: 0 ${rem(1)};
    content: "|";
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
`;

const StyledTitle = styled.h1`
  ${({ theme }) =>
    `
    ${fontParser("detailTitle")};
    color: ${theme.color.key.dark};
    padding: ${rem(8)} ${rem(16)};
    font-weight: bold;
    margin-bottom:0px !important;

    @media only screen and (min-width: ${theme.breakPoint}px) {
        display: flex;
        align-items: center;
    }

`}
`;

const StyledContentWrapper = styled.div`
  ${({ theme }) => `
  padding: ${rem(16)};
  position: relative;
  @media only screen and (min-width: ${theme.breakPoint}px) {
    padding: ${rem(16)} ${rem(8)};
  }
`}
`;

const StyledContent = styled.div`
  ${({ theme }) => `
  h2,
  h3,
  h4,
  h5,
  h6{
    margin-top: ${rem(4)};
    margin-bottom: ${rem(4)};
  }

  h1{
    margin-bottom: ${rem(16)};
    ${fontParser("title1")}
  }

  h2{
    ${fontParser("blogCaption")};
    margin-bottom: ${rem(4)};
  }

  h3{
    ${fontParser("title3")}
  }

  h2,
  h3,
  h4,
  h5,
  h6{
    margin-top: ${rem(4)};
    margin-bottom: ${rem(4)};
  }
  p:empty {
    display: none;
  }
  p {
    margin-bottom:  ${rem(12)};
  }
  p, ul li{
    font-size: ${rem(14)};
    line-height: ${rem(22)};
  }

  hr {
    margin: ${rem(32)} 0 ${rem(8)} 0;
    border-color: ${theme.color.globalMenu.primary};
  }

  a {
    color: ${theme.color.key.dark};
    font-weight: bold;
  }

  @media only screen and (min-width: ${theme.breakPoint}px) {
    padding: 0 ${rem(8)};
    p, ul li{
      font-size: ${rem(16)};
      line-height: ${rem(24)};
    }
  }

  img {
    margin-right: ${rem(10)};
  }
`}
`;

const StyledDate = styled.div`
  ${({ theme }) =>
    `
    font-weight: bold;
    font-size: ${rem(16)};
`}
`;

const StyledCtaButton = styled(CtaButton)`
  ${({ theme }) => `
  display: none;

  @media only screen and (min-width: ${theme.breakPoint}px) {
    display: flex;
    color: ${theme.color.globalMenu.dark};
    font-weight: bold;
    font-size: ${rem(16)};
    margin-left: ${rem(56)};
    margin-top: ${rem(24)};
  }
`}
`;

const BlogDetail = () => {
  const { blogActiveFilter } = useGlobalState();
  const { blogFilterList } = useGlobalState();
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [blogID, setBlogID] = useState(null);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const { trackPageView, trackEvent } = useMatomo();

  // Track page view
  useEffect(() => {
    trackPageView({
      documentTitle: "Blog Detail", // optional
    });
  }, []);

  useEffect(() => {
    if (slug !== undefined) {
      getBlogsBySlug(slug).then((data) => {
        const result = data.data
          .filter((blog) => {
            return blog.translations[0]?.slug === slug;
          })
          .map((b) => b);
        if (result.length > 0) {
          setBlogID(result[0].id);
        }
      });
    }
  }, [slug]);

  useEffect(() => {
    if (blogID !== null) {
      getBlogsByID(blogID).then((data) => {
        const result = data.data
          .filter((blog) => {
            return blog.id === blogID;
          })
          .map((b) => {
            const languageID = i18n.language === "nl" ? "nl-NL" : "en-US";
            const blog = b.translations.filter((obj) => {
              return obj.languages_id === languageID;
            });

            b.title =
              blog.length > 0 && blog[0].title !== "" ? blog[0].title : "";
            b.content =
              blog.length > 0 && blog[0]?.content !== "" ? blog[0].content : "";
            b.categories =
              b.categories && b.categories.length > 0 ? b.categories : [];

            return b;
          });

        if (result.length > 0) {
          setBlog(result[0]);
        }
      });
    }
  }, [blogID, i18n.language]);

  const handleSubNavClick = (index) => {
    dispatch({
      type: "SET_BLOG_FILTER",
      payload: index,
    });

    navigate("/blog");
  };

  return (
    <PagePanel>
      <BlogDetailHeader>
        <StyledListDetail>
          {blogFilterList.map((item, index) => {
            return (
              <StyledListItem
                key={index}
                $active={blogActiveFilter === index}
                onClick={() => handleSubNavClick(index)}
              >
                {t(`blog.${item.title}`)}
              </StyledListItem>
            );
          })}
        </StyledListDetail>
      </BlogDetailHeader>
      <StyledCtaButton to={"/blog"} direction="left">
        {t("buttons.return")}
      </StyledCtaButton>
      {blog === null && <></>}
      {blog && (
        <StyledPageContent>
          {blog.categories && (
            <StyledCategoryWrapper>
              {blog.categories.map((category, key) => {
                return (
                  <StyledCategory key={key}>
                    {t(`blog.${category}`)}
                  </StyledCategory>
                );
              })}
              <StyledDate>
                {new Date(blog.date_created).toLocaleDateString(
                  t.language === "en" ? "en-US" : "nl-NL"
                )}
              </StyledDate>
            </StyledCategoryWrapper>
          )}
          <StyledTitle>{blog.title}</StyledTitle>

          <StyledContentWrapper>
            {blog.content && (
              <StyledContent>{parse(blog.content)}</StyledContent>
            )}
            <SharePage url={currentUrl}></SharePage>
          </StyledContentWrapper>
        </StyledPageContent>
      )}
    </PagePanel>
  );
};

export default BlogDetail;
