import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useState as useGlobalState, useDispatch } from "../hooks/useReducer";
import RoundButton from "./RoundButton";
import { rem } from "polished";
import fontParser from "../utils/fontParser";
import { useTranslation } from "react-i18next";
import Icon from "./Icons";
import { getTranslatedReisType } from "../utils/helperFunctions";

const StyledInnerPanel = styled.div`
  ${({ theme, $colored = false, $hideOnMobile = false }) => `
    padding: ${rem(16)} ${rem(16)} ${rem(24)};
    display: ${$hideOnMobile ? "none" : "block"};
    @media only screen and (min-width: ${theme.breakPoint}px) {
      padding: ${rem(24)};
      display: block; 
    }
  `}
`;

const StyledCloseButton = styled(RoundButton)`
  ${({ theme }) => `
    position: absolute;
    top: ${rem(8)};
    right: ${rem(8)};
    background-color: ${theme.color.key.black};
  `}
`;

const StyledMainFilterTitle = styled.div`
  ${({ theme }) => `
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${theme.color.key.white};
  background-color: ${theme.color.key.dark};
  padding: ${rem(8)} ${rem(12)};
  font-weight: bold;
  `}
`;

const StyledIcon = styled(Icon)`
  ${({ theme }) => `
  background-color: ${theme.color.humanTrafficking.default};
  width: ${rem(16)};
  height: ${rem(16)};
  border-radius: ${rem(16)};
  margin-right: ${rem(8)};
  color: ${theme.color.humanTrafficking.default};
  @media only screen and (min-width: ${theme.breakPoint}px) {
    color: ${theme.color.key.dark};
    width: ${rem(32)};
    height: ${rem(32)};
  }
`}
`;

const StyledLegendaWrapper = styled.div`
  ${({ theme }) => `
  background-color: ${theme.color.key.white};
  padding: ${rem(16)};
  `}
`;

const StyledLegendaItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};
`;

const StyledLegendaIcon = styled(Icon)`
  ${({ theme, $tint = "dark", $padding = "0" }) => `
  width: ${rem(16)};
  height: ${rem(16)};
  border-radius: ${rem(16)};
  background-color: ${theme.color.destination[$tint]};
  color: ${theme.color.destination[$tint]};
  margin-right: ${rem(8)};
  padding: ${$padding}px;
  @media only screen and (min-width: ${theme.breakPoint}px) {
    width: ${rem(32)};
    height: ${rem(32)};
    background-color: ${theme.color.key.light};
    border-color: ${theme.color.destination.dark};
    color: ${theme.color.destination.dark};
  }
  `}
`;

const StyledTravelTypeList = styled.ul`
  ${({ theme }) => `
  padding-left: 0;
  list-style: none;
  background-color: ${theme.color.key.white};
  padding: ${rem(16)};
  `}
`;

const StyledTravelTypeItem = styled.li`
  ${({ $active }) => `
    display: flex;
    align-items: center;
    margin-bottom: ${rem(12)};
    cursor: pointer;
    &:hover, 
    &:active {
      opacity: .5;
    }
    opacity: ${$active ? "1" : ".5"};
  `}
`;

const StyledTravelTypeIcon = styled(Icon)`
  ${({ theme, $color }) => `
  width: ${rem(16)};
  height: ${rem(16)};
  border-radius: ${rem(16)};
  color: ${theme.color.key.white};
  background-color: ${
    $color ? theme.color.travel[$color].primary : theme.color.key.light
  };
  margin-right: ${rem(8)};
  @media only screen and (min-width: ${theme.breakPoint}px) {
    width: ${rem(32)};
    height: ${rem(32)};
  }
  `}
`;

const StyledFilterPanel = styled.div`
  ${({ theme, $filter }) => `
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: ${$filter ? "0" : "100%"};
        transition: left 0.35s ease;
        z-index: 10;
        overflow-y: scroll;
        background-color: ${theme.color.key.light};
        @media only screen and (min-width: ${theme.breakPoint}px) {
            transition: none;
            ${
              $filter
                ? `
                left: 100%;
                width: 440px;
                `
                : ``
            }
            
        }
    `}
`;

const StyledResetButton = styled.button`
  ${({ theme }) => `
      background-color: transparent;
      border: none;
      padding-right: 20px;
      display: flex;
      margin-left: auto;
      font-size: ${rem(12)};
      line-height: ${rem(16)};
      font-weight: bold;
      font-family: 'Open Sans';
      font-style: normal;
      cursor: pointer;
      color: ${theme.color.key.white};
      text-decoration: underline;
`}
`;

const FilterPanel = () => {
  const { filterPanel, reis_types, map_filters_active } = useGlobalState();
  const dispatch = useDispatch();
  const ref = useRef();
  const { t, i18n } = useTranslation();
  const [filter, setFilter] = useState(map_filters_active);

  useEffect(() => {
    if (filter !== null) {
      dispatch({
        type: "SET_ACTICE_MAP_FILTERS",
        payload: filter,
      });
    }
  }, [filter, dispatch]);

  useEffect(() => {
    if (filterPanel) {
      document.addEventListener("mousedown", handleDocumentClick);
    } else {
      document.removeEventListener("mousedown", handleDocumentClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [filterPanel]);

  const handleDocumentClick = (event) => {
    if (ref && !ref.current.contains(event.target)) {
      handleCloseFilter();
    }
  };

  const handleCloseFilter = () => {
    dispatch({ type: "CLOSE_FILTER_PANEL" });
  };

  const handleReisTypeFilter = (id) => {
    let payload = filter;
    if (payload.reis_types.includes(id)) {
      payload.reis_types = payload.reis_types.filter((data) => data !== id);
    } else {
      payload.reis_types.push(id);
    }

    setFilter((current) => {
      return {
        ...current,
        reis_types: payload.reis_types,
      };
    });
  };

  const handleResetFilters = () => {
    setFilter({
      reis_types: [1, 2, 3, 4, 5, 6, 7, 8],
    });
  };

  return (
    <StyledFilterPanel ref={ref} $filter={filterPanel}>
      <StyledCloseButton id="close" onClick={handleCloseFilter} />
      <StyledInnerPanel $colored={true}>
        <StyledMainFilterTitle>
          {t("mapFilterPanel.travelFilterTitle")}
          <StyledResetButton onClick={() => handleResetFilters()}>
            Reset Filter
          </StyledResetButton>
        </StyledMainFilterTitle>
        <StyledTravelTypeList>
          {reis_types.map(({ id, naam, color }) => (
            <StyledTravelTypeItem
              $active={map_filters_active.reis_types.includes(parseInt(id))}
              key={id}
              onClick={() => handleReisTypeFilter(parseInt(id))}
            >
              <StyledTravelTypeIcon id="Travel" $color={color} />
              {getTranslatedReisType(naam, i18n.language)}
            </StyledTravelTypeItem>
          ))}
        </StyledTravelTypeList>
      </StyledInnerPanel>
      <StyledInnerPanel>
        <StyledMainFilterTitle>
          {t("mapFilterPanel.legendaFilterTitle")}
        </StyledMainFilterTitle>
        <StyledLegendaWrapper>
          <StyledLegendaItem>
            <StyledIcon id="Enslaved" />
            {t("mapFilterPanel.legenda.enslaved")}
          </StyledLegendaItem>
          <StyledLegendaItem>
            <StyledLegendaIcon id="Sunk" />
            {t("mapFilterPanel.legenda.perish")}
          </StyledLegendaItem>
          <StyledLegendaItem>
            <StyledLegendaIcon id="Seized" $tint="default" $padding="1" />
            {t("mapFilterPanel.legenda.confiscated")}
          </StyledLegendaItem>
          <StyledLegendaItem>
            <StyledLegendaIcon id="Disapproved" className={"padding"} />
            {t("mapFilterPanel.legenda.disapproved")}
          </StyledLegendaItem>
          <StyledLegendaItem>
            <StyledLegendaIcon id="Cancelled" />
            {t("mapFilterPanel.legenda.cancelled")}
          </StyledLegendaItem>
        </StyledLegendaWrapper>
      </StyledInnerPanel>
    </StyledFilterPanel>
  );
};

export default FilterPanel;
