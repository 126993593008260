import { useState as useGlobalState } from "../hooks/useReducer";
import { useState, useCallback, useEffect, useRef } from "react";
import OverviewHeader from "../components/OverviewHeader";
import OverviewListPeopleItem from "../components/OverviewListPeopleItem";
import OverviewList from "../components/OverviewList";
import PeopleDetailHeader from "../components/PeopleDetailHeader";
import PeopleDetailBody from "../components/PeopleDetailBody";
import RoundButton from "../components/RoundButton";
import Dropdown from "../components/Dropdown";
import { StyledDropDownItem } from "../components/Dropdown";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { rem } from "polished";
import { useQuery } from "@apollo/client";
import {
  getVoyagesByPerson,
  getVoyageTypesByIDs,
  personByID,
  getArchiveItemsById,
  getBlogItemsById,
} from "../api";
import { useTranslation } from "react-i18next";
import { useDispatch } from "../hooks/useReducer";
import { getTranslatedRole } from "../utils/helperFunctions";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const StyledPanel = styled.section`
  ${({ theme, $detail }) => `
    position: fixed;
    top: 0;
    bottom: ${rem(56)};
    left: 0;
    width: 100%;
    @media only screen and (min-width: ${theme.breakPoint}px) {
        bottom: 0;
        width: calc(100% - ${rem(167)});
        left: ${$detail ? rem(-358) : rem(167)}};
        transition: all ${theme.transition.timing} ${theme.transition.function};
    }
    `}
`;

const StyledOverviewContainer = styled.div`
  ${({ theme, $detail }) => `
        position: absolute;
        bottom: 0;
        top: 0; 
        z-index: 0;
        overflow-y: ${$detail ? "hidden" : "scroll"};
        overflow-x: ${$detail ? "unset" : "hidden"};
        
        @media only screen and (min-width: ${theme.breakPoint}px) {
          transition: all ${theme.transition.timing} ${
    theme.transition.function
  };
            width: 100%;
            
        }
    `}
`;

const StyledDetailPanel = styled.div`
  ${({ theme, $detail }) => `
        background-color: ${theme.color.key.light};
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: ${$detail ? "0" : "100%"};
        transition: left ${theme.transition.timing} ${
    theme.transition.function
  };
        z-index: 10;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: ${theme.breakPoint}px) {
            transition: none;
            left: ${$detail ? "100%" : "0"};
            width: ${$detail ? `${rem(525)}` : "0"};
        }
    `}
`;

const StyledRoundButton = styled(RoundButton)`
  ${({ theme, $hasLinks }) => `
      position: absolute;
      bottom: ${rem(-12)}; 
      right: ${rem(16)};
      z-index: 1;
    `}
`;

const People = () => {
  const { people, bemannings_rollen } = useGlobalState();
  const [filteredPeople, setFilteredPeople] = useState([]);
  const { peopleId } = useParams();
  const [peopleData, setPeopleData] = useState(null);
  const [voyageData, setVoyageData] = useState([]);
  const [voyageTypeIDs, setVoyageTypeIDs] = useState([]);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [archiveItems, setArchiveItems] = useState(null);
  const [blogItems, setBlogItems] = useState(null);
  const navigate = useNavigate();
  const ref = useRef();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { data, error } = useQuery(personByID(peopleId), {
    skip: peopleId === undefined,
  });

  const { trackPageView, trackEvent } = useMatomo();

  // Track page view
  useEffect(() => {
    trackPageView({
      documentTitle: "People", // optional
    });
  }, []);

  useEffect(() => {
    if (
      selectedOption === null ||
      selectedOption === "Alle personen" ||
      selectedOption === "All persons"
    ) {
      setFilteredPeople(people);
    } else {
      setFilteredPeople(
        people.filter(({ rollen }) => {
          return (
            rollen.filter(({ rollen_id }) => rollen_id.rol === selectedOption)
              .length > 0
          );
        })
      );
    }
  }, [people, selectedOption]);

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      if (error) {
        dispatch({ type: "SET_ERROR" });
      } else {
        if (data.personen_by_id !== null) {
          setPeopleData(data.personen_by_id);
          document.addEventListener("mousedown", handleDocumentClick);
        } else {
          document.removeEventListener("mousedown", handleDocumentClick);
        }
      }

      return () => {
        document.removeEventListener("mousedown", handleDocumentClick);
      };
    }
  }, [data, error, dispatch]);

  useEffect(() => {
    if (peopleId !== undefined) {
      getVoyagesByPerson(peopleId).then((data) => {
        setVoyageData(data.data);
        setVoyageTypeIDs(
          data.data.map(({ type }) => {
            return type;
          })
        );
      });

      getArchiveItemsById(peopleId).then((data) => {
        if (data.data !== undefined || data.error !== undefined) {
          if (data.error) {
            dispatch({ type: "SET_ERROR" });
          } else {
            setArchiveItems(data.data);
          }
        }
      });

      if (i18n.language !== null) {
        getBlogItemsById(peopleId, i18n.language).then((data) => {
          if (data.data !== undefined || data.error !== undefined) {
            if (data.error) {
              dispatch({ type: "SET_ERROR" });
            } else {
              setBlogItems(data.data);
            }
          }
        });
      }
    }
  }, [peopleId, i18n.language, dispatch]);

  useEffect(() => {
    if (voyageTypeIDs.length > 0) {
      getVoyageTypesByIDs(voyageTypeIDs).then((data) => {
        setVoyageData((voyages) => {
          return voyages.map((voyage) => {
            voyage.voyage = data.data.find((e) => e.id === voyage.type);
            return voyage;
          });
        });
      });
    }
  }, [voyageTypeIDs]);

  const handleCloseDetail = useCallback(() => {
    setPeopleData(null);
    navigate("/people");
  }, [navigate]);

  const handleDocumentClick = useCallback(
    (event) => {
      if (
        ref &&
        !ref.current.contains(event.target) &&
        event.target.tagName !== "A"
      ) {
        handleCloseDetail();
      }
    },
    [handleCloseDetail]
  );

  const handlePageFilter = (id, value) => {
    setSelectedOption(value);
    setIsOpenDropdown(false);
  };

  const handleOpenFilter = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  return (
    <StyledPanel $detail={peopleData}>
      <StyledOverviewContainer $detail={peopleData}>
        <OverviewHeader isDetailOpen={peopleId !== undefined}>
          <Dropdown
            isOpen={isOpenDropdown}
            selectedOption={
              getTranslatedRole(selectedOption, i18n.language) ||
              t(`people.headerDefault`)
            }
            items={people}
            onDataClick={handlePageFilter}
            onDropDownClick={handleOpenFilter}
          >
            <StyledDropDownItem
              index={0}
              onClick={() => handlePageFilter(null, t(`people.headerDefault`))}
            >
              {t(`people.headerDefault`)}
            </StyledDropDownItem>
            {bemannings_rollen.map(({ rol, id }, index) => {
              return (
                <StyledDropDownItem
                  key={index + 1}
                  onClick={() => handlePageFilter(id, rol)}
                >
                  {getTranslatedRole(rol, i18n.language)}
                </StyledDropDownItem>
              );
            })}
          </Dropdown>
        </OverviewHeader>
        <OverviewList>
          {filteredPeople.map((item, index) => {
            return (
              <OverviewListPeopleItem
                item={item}
                key={index}
                isDetailOpen={peopleId !== undefined}
                isSelected={peopleId === item.id}
              />
            );
          })}
        </OverviewList>
      </StyledOverviewContainer>
      {peopleData && (
        <StyledDetailPanel $detail={peopleData} ref={ref}>
          <PeopleDetailHeader
            data={peopleData}
            links={
              archiveItems && archiveItems.filter((item) => item.is_overview)
            }
          >
            <StyledRoundButton
              id="close"
              onClick={handleCloseDetail}
              $hasLinks={
                archiveItems && archiveItems.filter((item) => item.is_overview)
              }
            />
          </PeopleDetailHeader>
          <PeopleDetailBody
            data={peopleData}
            voyageData={voyageData}
            archiveItems={archiveItems}
            blogItems={blogItems}
          />
        </StyledDetailPanel>
      )}
    </StyledPanel>
  );
};

export default People;
