import { useState as useGlobalState, useDispatch } from "../hooks/useReducer";
import { useState, useEffect } from "react";
import PagePanel from "../components/PagePanel";
import PageHeader from "../components/PageHeader";
import BlogItem from "../components/BlogItem";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Dropdown from "../components/Dropdown";
import { rem } from "polished";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import {
  StyledDropDownContainer,
  StyledDropDownItem,
} from "../components/Dropdown";
import {
  StyledNavigation,
  StyledList,
  StyledListItem,
} from "../components/PageSubNavigation";

const StyledDropdownWrapper = styled(StyledDropDownContainer)`
  ${({ theme }) => `
  display: flex;
  @media only screen and (min-width: ${theme.breakPoint}px) {
    display: none;
  }
`}
`;

const StyledBlogContent = styled.div`
  ${({ theme }) =>
    `
    margin: 0 auto;
    padding: ${rem(24)};

    @media only screen and (min-width: ${theme.breakPoint}px) {
      max-width: ${rem(1100)};
      margin-left:${rem(100)};
      column-count: 3;
      column-gap: ${rem(24)};
      padding: ${rem(32)} 0;
    }
    `}
`;

const Blog = () => {
  const { blogs } = useGlobalState();
  const { blogActiveFilter, blogFilterList } = useGlobalState();
  const [filterdBlogs, setFilteredBlogs] = useState(blogs);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { trackPageView, trackEvent } = useMatomo();

  // Track page view
  useEffect(() => {
    trackPageView({
      documentTitle: "Blog overview", // optional
    });
  }, []);

  useEffect(() => {
    if (blogActiveFilter === 0) {
      setFilteredBlogs(blogs);
    } else {
      setFilteredBlogs(
        blogs.filter(({ categories }) =>
          categories.includes(
            blogFilterList[blogActiveFilter].title.toLowerCase()
          )
        )
      );
    }
  }, [blogs, blogActiveFilter, blogFilterList]);

  const handleSubNavClick = (index) => {
    dispatch({
      type: "SET_BLOG_FILTER",
      payload: index,
    });
  };

  const handlePageFilter = (index) => {
    dispatch({
      type: "SET_BLOG_FILTER",
      payload: index,
    });
    setIsOpenDropdown(false);
  };

  const handleOpenFilter = () => {
    setIsOpenDropdown(!isOpenDropdown);
  };

  return (
    <PagePanel>
      <PageHeader title={"Blog"}>
        <StyledNavigation $isPage={true}>
          <StyledList>
            {blogFilterList.map((item, index) => {
              return (
                <StyledListItem
                  key={index}
                  $active={blogActiveFilter === index}
                  onClick={() => handleSubNavClick(index)}
                >
                  {t(`blog.${item.title}`)}
                </StyledListItem>
              );
            })}
          </StyledList>
        </StyledNavigation>
        <StyledDropdownWrapper>
          <Dropdown
            isOpen={isOpenDropdown}
            selectedOption={
              blogFilterList[blogActiveFilter].title || t(`blog.menu`)
            }
            items={blogFilterList}
            onDataClick={handlePageFilter}
            onDropDownClick={handleOpenFilter}
          >
            {blogFilterList.map((blog, index) => {
              return (
                <StyledDropDownItem
                  key={index + 1}
                  onClick={() => handlePageFilter(index)}
                >
                  {blog.title}
                </StyledDropDownItem>
              );
            })}
          </Dropdown>
        </StyledDropdownWrapper>
      </PageHeader>
      <StyledBlogContent>
        {filterdBlogs.map((blog, index) => {
          return <BlogItem key={index} blog={blog} />;
        })}
      </StyledBlogContent>
    </PagePanel>
  );
};

export default Blog;
