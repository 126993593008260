import styled from "styled-components";
import { rem } from "polished";
import LogoMcc from "./LogoMcc";

export const StyledPageHeader = styled.header`
  ${({ theme }) =>
    `
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: ${rem(13)};
  padding-top: ${rem(14)};
  border-bottom: ${rem(1)} solid ${theme.color.globalMenu.dark};
  left: 0;
  top: 0;
  right: 0;
  position: sticky;
  background-color: ${theme.color.key.light};
  z-index: 2;
  padding-left: ${rem(32)};
  @media only screen and (min-width: ${theme.breakPoint}px) {
    padding-top: ${rem(22)};
    padding-bottom: ${rem(16)};
}
  `}
`;

const StyledLogo = styled(LogoMcc)`
  ${({ theme, $isDetailOpen }) =>
    `
    margin-right: ${rem(24)};

  @media only screen and (min-width: ${theme.breakPoint}px) {
    margin-right:  ${rem(48)};
  }
  `}
`;

const OverviewHeader = ({ title = "", children = null }) => {
  return (
    <StyledPageHeader>
      {children}
      <StyledLogo />
    </StyledPageHeader>
  );
};

export default OverviewHeader;
