import { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GlobalStyle from "./GlobalStyles";
import theme from "./theme";
import { ThemeProvider } from "styled-components";
import Home from "./pages/Home";
import Ships from "./pages/Ships";
import Explain from "./pages/Explain";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import People from "./pages/People";
import Map from "./pages/Map";
import Nav from "./components/MainNavigation";
import Error from "./components/Error";
import GraphqlProvider, {
  main,
  overviewShips,
  overviewPeople,
  getBlogsByLang,
  getHelpPagesByLang,
  getVoyages,
  getPoints_0,
  getPoints_1,
  getPoints_2,
  getPoints_3,
  getPoints_4,
  getPoints_5,
  getPoints_6,
  getPoints_7,
} from "./api";
import { useQuery } from "@apollo/client";
import ReducerProvider, { useDispatch } from "./hooks/useReducer";
import { useTranslation } from "react-i18next";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import i18n from "i18next";

const Bootstrap = () => {
  const { i18n } = useTranslation();
  const mainQuery = useQuery(main);
  const shipQuery = useQuery(overviewShips);
  const peopleQuery = useQuery(overviewPeople);
  const dispatch = useDispatch();

  useEffect(() => {
    getPoints_0().then((data) => {
      if (data !== undefined) {
        dispatch({
          type: "SET_POINTS_0",
          payload: data,
        });
      }
    });
    getPoints_1().then((data) => {
      if (data !== undefined) {
        dispatch({
          type: "SET_POINTS_1",
          payload: data,
        });
      }
    });
    getPoints_2().then((data) => {
      if (data !== undefined) {
        dispatch({
          type: "SET_POINTS_2",
          payload: data,
        });
      }
    });
    getPoints_3().then((data) => {
      if (data !== undefined) {
        dispatch({
          type: "SET_POINTS_3",
          payload: data,
        });
      }
    });
    getPoints_4().then((data) => {
      if (data !== undefined) {
        dispatch({
          type: "SET_POINTS_4",
          payload: data,
        });
      }
    });
    getPoints_5().then((data) => {
      if (data !== undefined) {
        dispatch({
          type: "SET_POINTS_5",
          payload: data,
        });
      }
    });
    getPoints_6().then((data) => {
      if (data !== undefined) {
        dispatch({
          type: "SET_POINTS_6",
          payload: data,
        });
      }
    });
    getPoints_7().then((data) => {
      if (data !== undefined) {
        dispatch({
          type: "SET_POINTS_7",
          payload: data,
        });
      }
    });
  }, [dispatch]);

  useEffect(() => {
    getVoyages().then((data) => {
      if (data.data !== undefined || data.error !== undefined) {
        if (data.error) {
          dispatch({ type: "SET_ERROR" });
        } else {
          dispatch({
            type: "SET_REIZEN",
            payload: data.data,
          });
        }
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (mainQuery.data !== undefined || mainQuery.error !== undefined) {
      if (mainQuery.error) {
        dispatch({ type: "SET_ERROR" });
      } else {
        dispatch({
          type: "SET_REIS_TYPES",
          payload: mainQuery.data.reis_types,
        });
        dispatch({
          type: "SET_SCHIP_TYPES",
          payload: mainQuery.data.schip_types,
        });
        dispatch({
          type: "SET_BEMANNINGS_ROLLEN",
          payload: mainQuery.data.bemannings_rollen,
        });
        dispatch({
          type: "SET_MAP_ROUTES",
          payload: mainQuery.data.map_routes
            .filter(
              ({ bestemming1, bestemming2, path }) =>
                !(bestemming1 === null || bestemming2 === null || path === null)
            )
            .reduce((acc, { path, bestemming1, bestemming2 }) => {
              acc[`${bestemming1.id}-${bestemming2.id}`] = path.coordinates;
              return acc;
            }, {}),
        });
        dispatch({
          type: "SET_MAP_LOCATIONS",
          payload: mainQuery.data.locaties.reduce((acc, { id, marker }) => {
            acc[`${id}`] = marker.coordinates;
            return acc;
          }, {}),
        });
      }
    }
  }, [mainQuery.data, mainQuery.error, dispatch]);

  useEffect(() => {
    if (shipQuery.data !== undefined || shipQuery.error !== undefined) {
      if (shipQuery.error) {
        dispatch({ type: "SET_ERROR" });
      } else {
        dispatch({ type: "SET_SHIP_LIST", payload: shipQuery.data.schepen });
      }
    }
  }, [shipQuery.data, shipQuery.error, dispatch]);

  useEffect(() => {
    if (peopleQuery.data !== undefined || peopleQuery.error !== undefined) {
      if (peopleQuery.error) {
        dispatch({ type: "SET_ERROR" });
      } else {
        const peopleForSort = [...peopleQuery.data.personen];
        dispatch({
          type: "SET_PEOPLE_LIST",
          payload: peopleForSort.sort((a, b) =>
            a.naam.toLowerCase().localeCompare(b.naam.toLowerCase())
          ),
        });
      }
    }
  }, [peopleQuery.data, peopleQuery.error, dispatch]);

  useEffect(() => {
    if (i18n.language !== undefined) {
      getBlogsByLang(i18n.language).then((data) => {
        if (data.data !== undefined || data.error !== undefined) {
          if (data.error) {
            dispatch({ type: "SET_ERROR" });
          } else {
            dispatch({
              type: "SET_BLOG_LIST",
              payload: data.data.filter((blog) => blog.status === "published"),
            });
          }
        }
      });
      getHelpPagesByLang(i18n.language).then((data) => {
        if (data.data !== undefined || data.error !== undefined) {
          if (data.error) {
            dispatch({ type: "SET_ERROR" });
          } else {
            dispatch({
              type: "SET_HELP_PAGES_LIST",
              payload: data.data.filter((page) => page.status === "published"),
            });
          }
        }
      });
    }
  }, [i18n.language, dispatch]);

  return null;
};
const instance = createInstance({
  urlBase: "https://analytics.zeeuwsarchief.nl",
  siteId: process.env.REACT_APP_SITEID,
});

function App() {
  return (
    <MatomoProvider value={instance}>
      <Suspense fallback="">
        <ReducerProvider>
          <GraphqlProvider>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Bootstrap />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/map" element={<Map />}>
                    <Route path=":voyageId" element={<Map />} />
                  </Route>
                  <Route path="/ships" element={<Ships />}>
                    <Route path=":shipId" element={<Ships />} />
                  </Route>
                  <Route path="/people" element={<People />}>
                    <Route path=":peopleId" element={<People />} />
                  </Route>
                  <Route path="/explain" element={<Explain />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/blog/:slug" element={<BlogDetail />} />
                </Routes>
                <Nav />
                <Error />
              </ThemeProvider>
            </BrowserRouter>
          </GraphqlProvider>
        </ReducerProvider>
      </Suspense>
    </MatomoProvider>
  );
}

export default App;
