export function calcDays(start, end) {
  let date_1 = new Date(end);
  let date_2 = new Date(start);

  let difference = date_1.getTime() - date_2.getTime();

  return Math.ceil(difference / (1000 * 3600 * 24));
}

export function formatDate(d, lang) {
  const date = new Date(d);
  return date.toLocaleDateString(lang, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
}

export function convertDateToAbsolute(d) {
  const start = new Date("1720-01-01");
  const date = new Date(d);

  const differenceInTime = date.getTime() - start.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  // 31025 is total days on timeline (365days * 85years).
  // Deviding days by totalsDays gives us the absoluteDate on timeline.
  return differenceInDays / 31025;
}

export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

export const debounce = function (func, delay) {
  let timer;
  return function () {
    //anonymous function
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

export const calcSpeed = (speed) => {
  const baseSpeed = 0.005;

  console.log("speed", (speed * baseSpeed) / 100);

  return (speed * baseSpeed) / 100;
};

export const getOrdinal = (n, lang) => {
  n = parseInt(n);
  let ord = "";
  if (lang === "en") {
    ord = "th";
    if (n % 10 === 1 && n % 100 !== 11) {
      ord = "st";
    } else if (n % 10 === 2 && n % 100 !== 12) {
      ord = "nd";
    } else if (n % 10 === 3 && n % 100 !== 13) {
      ord = "rd";
    }
  } else {
    ord = "e";
  }

  return n + ord;
};

export const getTranslatedReisType = (type = "", lang) => {
  if (lang === "nl") {
    switch (type) {
      case "Reis Nederlandse cariben":
        return "Reis Nederlandse Cariben";
      case "Reis Spaanse cariben":
        return "Reis Spaanse Cariben";
      default:
        return type;
    }
  } else {
    switch (type) {
      case "Driehoeksreis":
        return "Triangular Voyage";
      case "Reis West-Afrika":
        return "West Africa Voyage";
      case "Reis Nederlandse cariben":
        return "Dutch Caribbean Voyage";
      case "Reis Spaanse cariben":
        return "Spanish Caribbean Voyage";
      case "Verhuurd aan de Admiraliteit":
        return "Leased to the Admiralty";
      case "Kaperreis":
        return "Privateering Voyage";
      case "Verkenningsreis":
        return "Reconnaissance Voyage";
      case "Europareis":
        return "European Voyage";
      default:
        return type;
    }
  }
};

export const getTranslatedShipType = (type = "", lang) => {
  if (lang === "nl") {
    return type;
  } else {
    switch (type) {
      case "Fregat":
        return "Frigate";
      case "Snauw":
        return "Snow";
      case "Driemast-hoeker":
        return "Three-masted hoeker";
      case "Fluit":
        return "Flute";
      case "Bark":
        return "Barque";
      default:
        return type;
    }
  }
};

export const getTranslatedRole = (role = "", lang) => {
  if (lang === "nl") {
    return role;
  } else {
    switch (role) {
      case "Directeur":
        return "Director";
      case "Commissaris":
        return "Commissioner";
      case "Kapitein":
        return "Captain";
      case "Opperstuurman":
        return "First mate";
      case "Opvarende":
        return "Crew member";
      case "Handelsagent":
        return "Commercial agent";
      case "Leverancier":
        return "Salesman";
      default:
        return role;
    }
  }
};

export const getTranslatedDestination = (destination = "", lang) => {
  if (lang === "nl") {
    return destination;
  } else {
    switch (destination) {
      case "Bovenkust":
        return "Guinea Coast";
      case "De Bocht":
        return "Bight of Biafra";
      case "Amerikaanse Maagdeneilanden":
        return "British Virgin Islands";
      case "Atlantische Oceaan":
        return "Atlantic Ocean";
      case "Azoren":
        return "Azores";
      case "Brazilië":
        return "Brasil";
      case "IJsland":
        return "Iceland";
      default:
        return destination;
    }
  }
};

export const getTranslatedArchiveTitle = (title = "", lang) => {
  if (lang === "nl") {
    return title;
  } else {
    switch (title) {
      case "Instructies aan de kapitein":
        return "Instructions for the captain";
      case "Verzekeringspolis":
        return "Insurance policy";
      case "Paspoort Suriname":
        return "Passport Suriname";
      case "Brieven van de kapitein aan de directeuren":
        return "Letters from the captain to the directors";
      case "Brief van de kapitein aan de directeuren":
        return "Letter from the captain to the directors";
      case "Scheepsboek":
        return "Ship's book";
      case "Artikelbrief":
        return "Contract";
      case "Soldijrol":
        return "Payroll";
      case "Logboek I":
        return "Logbook I";
      case "Logboek II":
        return "Logbook II";
      case "Testamenten van opvarenden":
        return "Wills of sailors";
      case "Notariële akte stuurlieden":
        return "Notarial deed steersmen";
      case "Register met handelsgoederen":
        return "Register of commodities";
      case "Aanbesteding handelsgoederen":
        return "Tender for trade goods";
      case "Overzicht van reispapieren":
        return "List of travel documents";
      case "Betaling recognitie aan de WIC":
        return "Payment recognition to the WIC";
      case "Logboek III":
        return "Logbook III";
      case "Monsterrol":
        return "Muster roll";
      case "Correspondentie":
        return "Correspondence";
      case "Correspondentie I":
        return "Correspondence I";
      case "Correspondentie II":
        return "Correspondence II";
      case "Correspondentie III":
        return "Correspondence III";
      case "Correspondentie IV":
        return "Correspondence IV";
      case "Chirurgijnsjournaal":
        return "Surgeon's logbook";
      case "Chirurgijnsjournaal de Eenigheid 3e reis":
        return "Surgeon's logbook The Unity 3rd voyage";
      case "Chirurgijnsjournaal de Vrouw Johanna Cores 10e reis":
        return "Surgeon's logbook The Vrouw Johanna Cores 10th voyage";
      case "Chirurgijnsjournaal de Brandenburg 4e reis":
        return "Surgeon's logbook The Brandenburg 4th voyage";
      case "Chirurgijnsjournaal de Vergenoegen 3e reis":
        return "Surgeon's logbook The Vergenoegen 3rd voyage";
      case "Chirurgijnsjournaal de Zeemercuur 1e reis":
        return "Surgeon's logbook The Zeemercuur 2nd voyage";
      case "Chirurgijnsjournaal de Zeemercuur 2e reis":
        return "Surgeon's logbook The Zeemercuur 1st voyage";
      case "Logboek":
        return "Logbook";
      case "Handelsboek":
        return "Trade book";
      case "Handelsboek I":
        return "Trade book I";
      case "Handelsboek II":
        return "Trade book II";
      case "Handelsboek III":
        return "Trade book III";
      default:
        return title;
    }
  }
};
