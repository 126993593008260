import { rem } from "polished";

const theme = {
  color: {
    key: {
      dark: "#333333",
      default: "#696969",
      light: "#F2F2F2",
      white: "#FFFFFF",
      primary: "#00a5c8",
      black: "#000000",
    },
    ships: {
      dark: "#29B8CE",
      light: "#DFF4F8",
    },
    persons: {
      dark: "#CF5B2C",
      light: "#F7E5DD",
      secondary: "#eed965",
    },
    background: {
      dark: "#4D6C7E",
      default: "#CBC9AF",
      light: "#EDECDA",
      lightGray: "#EEEFF0",
      darkGray: "#231F20",
      xLight: "#E4E4E3",
    },
    globalMenu: {
      dark: "#4A0E12",
      primary: "#E05115",
    },
    humanTrafficking: {
      dark: "#C19A63",
      default: "#F4C582",
      light: "rgba(#F4C582, 0.24)",
    },
    date: {
      dark: "#BC4242",
      default: "#C73528",
      light: "#F49090",
      xLight: "#FFF4F3",
      highlight: "#E05115",
    },
    location: {
      dark: "#406539",
      default: "#61BA3C",
      light: "#A4F482",
      xLight: "#D7E5BF",
    },
    destination: {
      dark: "#1D75A8",
      default: "#48AAC1",
      light: "#84E2F7",
      xLight: "#E8F6FB",
    },
    travel: {
      default: {
        primary: "#00893c",
        light: "#E2EDE3",
      },
      westAfrika: {
        primary: "#00838b",
        light: "#E1ECED",
      },
      driehoek: {
        primary: "#00893c",
        light: "#E2EDE3",
      },
      spaans: {
        primary: "#0039bf",
        light: "#DCE1F4",
      },
      europa: {
        primary: "#d24803",
        light: "#F6E5DE",
      },
      nederland: {
        primary: "#0075bf",
        light: "#E0EAF5",
      },
      kaper: {
        primary: "#a71680",
        light: "#F0DFEC",
      },
      verkenning: {
        primary: "#e71954",
        light: "#F9E1E6",
      },
      admiraliteit: {
        primary: "#5e4f9c",
        light: "#E7E5EF",
      },
      retour: "#7083D5",
    },
  },
  fonts: {
    number: {
      small: {
        fontFamily: "PT Serif",
        size: rem(32),
        lineHeight: rem(40),
        fontWeight: "700",
      },
      large: {},
    },
    cta: {
      small: {
        fontFamily: "Open Sans",
        size: rem(32),
        lineHeight: rem(40),
        fontWeight: "700",
        letterSpacing: "1%",
      },
      large: {},
    },
    largeTitle: {
      small: {
        fontFamily: "PT Serif",
        size: rem(32),
        lineHeight: rem(36),
        fontWeight: "700",
      },
      large: {
        size: rem(52),
        lineHeight: rem(48),
      },
    },
    detailTitle: {
      small: {
        fontFamily: "PT Serif",
        size: rem(24),
        lineHeight: rem(28),
        fontWeight: "700",
      },
      large: {
        size: rem(44),
        lineHeight: rem(48),
      },
    },
    title1: {
      small: {
        fontFamily: "PT Serif",
        size: rem(16),
        lineHeight: rem(20),
        fontWeight: "700",
      },
      large: {
        size: rem(28),
        lineHeight: rem(36),
      },
    },
    title2: {
      small: {
        fontFamily: "PT Serif",
        size: rem(16),
        lineHeight: rem(24),
        fontWeight: "700",
      },
      large: {
        size: rem(24),
        lineHeight: rem(30),
      },
    },
    title3: {
      small: {
       // fontStyle: "italic",
        fontFamily: "PT Serif",
        size: rem(16),
        lineHeight: rem(24),
      },
      large: {
        size: rem(24),
        lineHeight: rem(32),
      },
    },
    title4: {
      small: {
        fontStyle: "italic",
        fontFamily: "PT Serif",
        size: rem(16),
        lineHeight: rem(24),
      },
      large: {
        size: rem(20),
        lineHeight: rem(24),
      },
    },
    title5: {
      small: {
        fontStyle: "italic",
        fontFamily: "PT Serif",
        size: rem(12),
        lineHeight: rem(16),
      },
      large: {
        size: rem(20),
        lineHeight: rem(24),
      },
    },
    title6: {
      small: {
        fontWeight: "700",
        fontFamily: "PT Serif",
        size: rem(12),
        lineHeight: rem(16),
      },
      large: {
        size: rem(16),
        lineHeight: rem(24),
      },
    },
    cardName: {
      small: {
        fontWeight: "700",
        fontFamily: "PT Serif",
        size: rem(16),
        lineHeight: rem(20),
      },
      large: {
        size: rem(20),
        lineHeight: rem(24),
      },
    },
    headline: {
      small: {
        fontFamily: "Open Sans",
        size: rem(14),
        lineHeight: rem(24),
        fontWeight: "500",
        letterSpacing: "1%",
      },
      large: {
        size: rem(24),
        lineHeight: rem(32),
      },
    },
    caption2: {
      small: {
        fontFamily: "Open Sans",
        size: rem(12),
        lineHeight: rem(16),
        fontWeight: "500",
        letterSpacing: "1%",
      },
      large: {
        size: rem(14),
        lineHeight: rem(18),
      },
    },
    cardType: {
      small: {
        fontFamily: "Open Sans",
        size: rem(12),
        lineHeight: rem(16),
        fontWeight: "500",
        letterSpacing: "1%",
      },
      large: {
        size: rem(18),
        lineHeight: rem(22),
      },
    },
    caption1: {
      small: {
        fontFamily: "Open Sans",
        size: rem(14),
        lineHeight: rem(16),
      },
      large: {},
    },
    blogCaption: {
      small: {
        fontFamily: "Open Sans",
        size: rem(15),
        fontWeight: "bold",
      },
      large: {
        size: rem(20),
      },
    },
    body: {
      small: {
        fontFamily: "Open Sans",
        size: rem(12),
        lineHeight: rem(16),
      },
      large: {
        size: rem(16),
        lineHeight: rem(20),
      },
    },
    bodyBold: {
      small: {
        fontFamily: "Open Sans",
        size: rem(12),
        lineHeight: rem(16),
        fontWeight: "700",
      },
      large: {},
    },
    archive: {
      small: {
        fontWeight: "700",
        fontFamily: "PT Serif",
        size: rem(10),
        lineHeight: rem(16),
      },
      large: {
        size: rem(16),
        lineHeight: rem(24),
      },
    },
  },
  breakPoint: 900,
  padding: {
    outer: rem(24),
    inner: rem(16),
  },
  transition: {
    timing: ".3s",
    function: "ease",
  },
};

export default theme;
