import styled from "styled-components";
import { useState } from "../hooks/useReducer";
import { fromRatio } from "./Timeline";
import { rem } from "polished";
import { useTranslation } from "react-i18next";
import fontParser from "../utils/fontParser";

const StyledPanel = styled.div`
  ${({ theme }) => `
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  @media only screen and (min-width: ${theme.breakPoint}px) {
    font-size: ${rem(32)};
    }
  `}
`;

const StyledTitle = styled.span`
  ${({ theme }) => `
    color: ${theme.color.key.dark};
  `}
`;

const StyledYear = styled.span`
  ${({ theme }) => `
    margin-left: ${rem(4)};
    color: ${theme.color.date.highlight};
    -webkit-text-stroke: ${rem(4)} transparent;
  `}
`;

const YearLabel = ({ className }) => {
  const { time } = useState();
  const { t } = useTranslation();

  return (
    <StyledPanel className={className}>
      <StyledTitle>{t("map.year.title")}</StyledTitle>
      <StyledYear>{fromRatio(time)}</StyledYear>
    </StyledPanel>
  );
};

export default YearLabel;
