import styled from "styled-components";
import { rem } from "polished";
import CtaButton from "./CtaButton";
import { useTranslation } from "react-i18next";
import { StyledPageHeader } from "./PageHeader";
import LogoMcc from "./LogoMcc";

const StyledCtaButton = styled(CtaButton)`
  ${({ theme }) => `
  svg{
    min-width: ${rem(21)};
  }
  
  @media only screen and (min-width: ${theme.breakPoint}px) {
    display: none;
  }
`}
`;

const StyledLogo = styled(LogoMcc)`
  ${({ theme }) =>
    `
    margin-right: ${rem(24)};

  @media only screen and (min-width: ${theme.breakPoint}px) {
    margin-right: ${rem(48)};
  }
  `}
`;

const PageHeader = ({ title = "", children = null }) => {
  const { t } = useTranslation();

  return (
    <StyledPageHeader>
      <StyledCtaButton to={"/blog"} direction="left">
        {t("buttons.return")}
      </StyledCtaButton>
      {children}
      <StyledLogo />
    </StyledPageHeader>
  );
};

export default PageHeader;
