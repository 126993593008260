import Icon from "./Icons";
import styled from "styled-components";
import { rem } from "polished";
import fontParser from "../utils/fontParser";
import { useTranslation } from "react-i18next";
import { StyledLinks, StyledLink, StyledArchiveIcon } from "./MapDetailHeader";
import { StyledColumn, StyledValue } from "./CardBodyContent";
import { getTranslatedShipType } from "../utils/helperFunctions";

const StyledDetailHeader = styled.div`
  ${({ theme }) => `
    color: ${theme.color.key.dark};
    background-color: ${theme.color.ships.dark};
    padding: ${rem(22)} ${rem(16)};
    display: flex;
    flex-direction: column;
    font-weight: bold;
    position: relative;
    @media only screen and (min-width: ${theme.breakPoint}px) {
      padding: ${rem(24)} ${rem(16)};
      margin: ${rem(16)} ${rem(16)} 0 ${rem(16)};
   }
`}
`;

export const StyledHeaderLabel = styled.span`
  ${({ theme }) => `
  ${fontParser("caption2")}
  display: block;
  color: ${theme.color.key.white};
  //font-weight: bold;
  text-transform: uppercase;
  `}
`;

const StyledDetailHeaderWrapper = styled.div`
  display: flex;
`;

const StyledDetailHeaderContent = styled.div`
  ${({ theme }) =>
    `
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
 `}
`;

const StyledIconWrapper = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const StyledIcon = styled(Icon)`
  ${({ theme }) => `
    color: ${theme.color.key.white};
    width: 140px;
    height: auto;
    `}
`;

const StyledHeaderTitle = styled.h2`
  ${fontParser("title1")}
  ${({ theme }) => `
  color: ${theme.color.key.white};
  `}
`;

const StyledHeaderTopTitle = styled.h3`
  ${fontParser("body")}
  font-weight:normal;
`;

const DetailHeader = ({ data, links = [], children = null }) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledDetailHeader>
      <StyledHeaderTopTitle>
        {getTranslatedShipType(data.scheepstype.naam, i18n.language)}
      </StyledHeaderTopTitle>
      <StyledHeaderTitle>{data.naam}</StyledHeaderTitle>
      <StyledDetailHeaderWrapper>
        <StyledDetailHeaderContent>
          {links && links.length > 0 && (
            <StyledLinks>
              {links.map(({ link, title }, id) => (
                <StyledLink
                  key={id}
                  href={link !== null ? link : ""}
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  <StyledArchiveIcon id="Link" />
                  {title}
                </StyledLink>
              ))}
            </StyledLinks>
          )}
          {!!data.lengte && (
            <StyledColumn>
              <StyledHeaderLabel>
                {t("shipDetail.shipLength")}
              </StyledHeaderLabel>
              <StyledValue>{`${data.lengte} m`}</StyledValue>
            </StyledColumn>
          )}
          {!!data.breedte && (
            <StyledColumn>
              <StyledHeaderLabel>{t("shipDetail.shipWidth")}</StyledHeaderLabel>
              <StyledValue>{`${data.breedte} m`}</StyledValue>
            </StyledColumn>
          )}
          {!!data.inhoud && (
            <StyledColumn>
              <StyledHeaderLabel>
                {t("shipDetail.shipVolume")}
              </StyledHeaderLabel>
              <StyledValue>
                {`${data.inhoud} m${String.fromCodePoint(0x00b3)}`}
              </StyledValue>
            </StyledColumn>
          )}
          {!!data.diepgang && (
            <StyledColumn>
              <StyledHeaderLabel>{t("shipDetail.shipDepth")}</StyledHeaderLabel>
              <StyledValue>{`${data.diepgang} m`}</StyledValue>
            </StyledColumn>
          )}
          {!!data.hoogte_tussendek && (
            <StyledColumn>
              <StyledHeaderLabel>
                {t("shipDetail.shipHeight")}
              </StyledHeaderLabel>
              <StyledValue>{`${data.hoogte_tussendek} m`}</StyledValue>
            </StyledColumn>
          )}
          {!!data.lasten && (
            <StyledColumn>
              <StyledHeaderLabel>{t("shipDetail.shipLoad")}</StyledHeaderLabel>
              <StyledValue> {!!data.lasten ? data.lasten : "-"}</StyledValue>
            </StyledColumn>
          )}
        </StyledDetailHeaderContent>

        <StyledIconWrapper>
          <StyledIcon id={data.scheepstype.naam} />
        </StyledIconWrapper>
      </StyledDetailHeaderWrapper>
      {children}
    </StyledDetailHeader>
  );
};

export default DetailHeader;
