import styled from "styled-components";
import { rem } from "polished";
import fontParser from "../utils/fontParser";
import { useTranslation } from "react-i18next";
import CtaButton from "../components/CtaButton";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const StyledBlogItem = styled(Link)`
  ${({ theme }) =>
    `
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: ${rem(24)};
    break-inside: avoid;
    text-decoration: none;
    background-color: ${theme.color.key.white};

`}
`;

const StyledChildWrapper = styled.div`
  ${({ theme }) =>
    `
  grid-row: 1 / -1;
  grid-column: 1;
`}
`;

const StyledImageWrapper = styled.div`
  ${({ theme }) =>
    `
        width: 100%;
        height: auto;
    }
`}
`;

const StyledCategory = styled.span`
  ${({ theme }) =>
    `    
    color: ${theme.color.globalMenu.primary};
    font-size: ${rem(12)};
    line-height: ${rem(16)};
  &:after {
    margin: 0 ${rem(1)};
    content: "|";
  `}
`;

const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: auto;
`;

const StyledContentWrapper = styled.div`
  ${({ theme }) =>
    `
    color: ${theme.color.key.dark};
    padding: ${rem(24)} ${rem(24)};
`}
`;

const StyledTitle = styled.h2`
  ${({ theme }) =>
    `
    ${fontParser("title3")};
    color: ${theme.color.key.dark};
    padding: 0 ${rem(8)} ${rem(16)} ${rem(24)};
`}
`;

const StyledContent = styled.div`
  ${({ theme }) =>
    `
    color: ${theme.color.key.dark};
    p {
      font-size: ${rem(14)};
      line-height: ${rem(22)};  
    }
 
    @media only screen and (min-width: ${theme.breakPoint}px) {
        flex-grow: 1;
    }
`}
`;

const StyledDate = styled.div`
  ${({ theme }) =>
    `
    color: ${theme.color.globalMenu.primary};
    font-size: ${rem(12)};
`}
`;

const StyledMetaWrapper = styled.div`
  ${({ theme }) =>
    `
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: ${rem(8)} ${rem(8)} 0 ${rem(24)};
`}
`;

const StyledCtaButton = styled(CtaButton)`
  ${({ theme }) =>
    `
    margin-top: ${rem(16)};
    color: ${theme.color.key.dark};
    svg {
        position: relative;
        top: ${rem(3)};
    }

    @media only screen and (min-width: ${theme.breakPoint}px) {
        svg{
            top: ${rem(2)};
        }
    }
`}
`;

const BlogItem = ({ blog }) => {
  const { t } = useTranslation();
  const publicationDate = new Date(blog.date_created).toLocaleDateString(
    t.language === "en" ? "en-US" : "nl-NL"
  );

  return (
    <StyledChildWrapper>
      <StyledBlogItem to={blog.translations[0].slug}>
        <StyledMetaWrapper>
          {blog.categories && (
            <>
              {blog.categories.map((category) => {
                return <StyledCategory>{t(`blog.${category}`)}</StyledCategory>;
              })}
            </>
          )}
          <StyledDate>{publicationDate}</StyledDate>
        </StyledMetaWrapper>
        <StyledTitle>{blog.translations[0].title}</StyledTitle>
        <StyledImageWrapper>
          <StyledImage
            src={`${process.env.REACT_APP_HOST}/assets/${blog.afbeelding}`}
          />
        </StyledImageWrapper>
        <StyledContentWrapper>
          <StyledContent>
            <p>
              {blog.translations[0].excerpt &&
                parse(blog.translations[0].excerpt)}
            </p>
            <StyledCtaButton direction="right">
              {t("blogItem.link")}
            </StyledCtaButton>
          </StyledContent>
        </StyledContentWrapper>
      </StyledBlogItem>
    </StyledChildWrapper>
  );
};

export default BlogItem;
