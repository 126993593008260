import styled from "styled-components";
import { rem } from "polished";

export const StyledNavigation = styled.nav`
  ${({ theme, $isPage }) => `
    display: ${$isPage === true ? "none" : "flex"};
    
    @media only screen and (min-width: ${theme.breakPoint}px) {
      display: flex;
    }
`}
`;

export const StyledList = styled.ul`
  display: flex;
  list-style: none;
  position: relative;
`;

export const StyledListItem = styled.li`
  ${({ theme, $active }) => `
    margin-right: ${rem(8)};
    padding: ${rem(8)};
    font-size: ${rem(14)};
    font-weight: bold;
    line-height: ${rem(16)};
    color: ${$active ? theme.color.globalMenu.primary : theme.color.key.dark};
    cursor: pointer;


    &:hover{
      background-color: ${theme.color.globalMenu.dark};
      color: ${theme.color.key.white};
    }
`}
`;

const SubNavigation = ({ children = null }) => {
  return (
    <StyledNavigation>
      <StyledList>{children}</StyledList>
    </StyledNavigation>
  );
};

export default SubNavigation;
