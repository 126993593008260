import { along, length } from "@turf/turf";
import { cloneDeep } from "@apollo/client/utilities";
import reisTypeColor from "../utils/reisColorMapper";
import theme from "../theme";
import sortBy from "lodash.sortby";
import { constructIcon } from "../utils/icons";

export const defaultBounds = [
  [-86.484375, 62.2679226294176],
  [13.359375, -9.102096738726443],
];

const smoothCoords = (coords) => {
  return coords;
};

// Geojson / Path functions
const toPath = (coords) => {
  return {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: coords,
        },
      },
    ],
  };
};

const getPointOnPath = (coordinates, value) => {
  return coordinates[
    Math.max(
      0,
      Math.min(coordinates.length - 1, Math.round(coordinates.length * value))
    )
  ];
};

const interpolateCoords = (coords, steps) => {
  const gp = toPath(coords);
  const totalLength = length(gp.features[0]);
  let arc = [];

  for (let i = 0; i < totalLength; i += totalLength / (steps * 4)) {
    const segment = along(gp.features[0], i);
    arc.push(segment.geometry.coordinates);
  }

  return arc;
};

const oneDay = 24 * 60 * 60 * 1000;

const startDate = new Date(1720, 1, 1);
export const days = 31025; // 85 years * 365 days

export const parseDate = (d) => {
  return Math.round(Math.abs((startDate - new Date(d)) / oneDay));
};

const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

const getPath = (fromId, toId, reis, paths) => {
  const key = `${fromId}-${toId}`;
  if (paths[key] === undefined) {
    // console.log(`path ${key} is missing in: `, reis);
    return [];
  } else {
    const smoothPaths = objectMap(paths, (v) => {
      return smoothCoords(v);
    });
    return smoothPaths[key];
  }
};

export const mapReis = (reis, paths, locations) => {
  const clonedReis = cloneDeep(reis);
  const sortedBestemmingen = sortBy(reis.bestemmingen, "sort");

  // remap travel object
  const result = [
    {
      vertrek: parseDate(reis.datum_aanvang_reis),
      bestemming: reis.plaats_aanvang_reis,
    },
    ...sortedBestemmingen.map(({ vertrek, aankomst, bestemming, sort }) => {
      return {
        vertrek: parseDate(vertrek),
        aankomst: parseDate(aankomst),
        bestemming: bestemming.id,
        sort: sort,
      };
    }),
    {
      aankomst: parseDate(reis.datum_einde_reis),
      bestemming: reis.plaats_einde_reis,
    },
  ];

  // makes travels from places
  const patherizer = (acc, item, index) => {
    if (index > result.length - 2) {
      return acc;
    }

    const next = result[index + 1];
    acc.push({
      vertrek: item.vertrek,
      aankomst: next.aankomst,
      pathFrom: item.bestemming,
      pathTo: next.bestemming,
    });

    return acc;
  };

  const addStops = (acc, item, index) => {
    if (index > result.length - 2) {
      acc.push(item);
      return acc;
    }
    acc.push(item);
    const next = result[index + 1];

    if (item.aankomst < next.vertrek) {
      acc.push({
        vertrek: item.aankomst + 1,
        aankomst: next.vertrek - 1,
        pathFrom: item.pathTo,
        pathTo: next.bestemming,
      });
    }

    return acc;
  };

  clonedReis.journey = result
    .reduce(patherizer, [])
    .reduce(addStops, [])
    .map(({ vertrek, aankomst, pathFrom, pathTo }) => {
      if (aankomst - vertrek < 0) {
        console.log(
          "A path in the following 'Reis' went backwards in time: ",
          clonedReis,
          "result: ",
          aankomst - vertrek,
          "aankomst: ",
          aankomst,
          "vertrek ",
          vertrek
        );
      }
      return {
        vertrek: vertrek / days,
        aankomst: aankomst / days,
        path:
          pathFrom === pathTo
            ? Array(aankomst - vertrek).fill(locations[pathFrom])
            : interpolateCoords(
                getPath(pathFrom, pathTo, reis, paths),
                aankomst - vertrek
              ),
      };
    })
    .reduce((acc, { path }) => {
      acc.push(...path);
      return acc;
    }, [])
    .filter((i) => i !== undefined);

  // meta info
  clonedReis.start = parseDate(reis.datum_aanvang_reis) / days;
  clonedReis.end = parseDate(reis.datum_einde_reis) / days;

  return clonedReis;
};

export const buildMapLines = (data) => {
  const lines = data.map(({ journey, type, start, end }) => {
    return {
      type: "Feature",
      properties: {
        color: theme.color.travel[reisTypeColor(type.toString())].primary,
        start: start,
        end: end,
        type: type,
      },
      geometry: {
        type: "LineString",
        coordinates: journey,
      },
    };
  });

  return [
    {
      type: "FeatureCollection",
      features: lines,
    },
  ];
};

export const buildMapPoints = (data) => {
  const dots = [];
  const json = {
    0: {},
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    7: {},
  };

  // 40000 = 1 / 0.000025.
  // 1 is the maximium time (year 1805), 0.000025 is the smallest possible step (1 day)
  // foreach step in time we're going to look if a point matches that time point.
  for (let i = 0; i <= 40000; i++) {
    const time = i / 40000;

    for (const item of data) {
      const { journey, start, end, id, type, mensenhandel, reden_onvoltooid } =
        item;

      if (time >= start && time <= end) {
        if (
          getPointOnPath(journey, (time - start) / (end - start)) === undefined
        ) {
          continue;
        }
        console.log(item);

        dots.push({
          type: "Feature",
          properties: {
            type: type,
            icon: constructIcon(type, mensenhandel, reden_onvoltooid),
            journey_id: id,
            time: time,
          },
          geometry: {
            type: "Point",
            coordinates: getPointOnPath(
              journey,
              (time - start) / (end - start)
            ),
          },
        });
      }
    }
    if (time >= 0 && time <= 0.124975) {
      json[0][time] = {
        type: "FeatureCollection",
        features: dots.filter((dot) => dot.properties.time === time),
      };
    } else if (time >= 0.125 && time <= 0.249975) {
      json[1][time] = {
        type: "FeatureCollection",
        features: dots.filter((dot) => dot.properties.time === time),
      };
    } else if (time >= 0.25 && time <= 0.374975) {
      json[2][time] = {
        type: "FeatureCollection",
        features: dots.filter((dot) => dot.properties.time === time),
      };
    } else if (time >= 0.375 && time <= 0.499975) {
      json[3][time] = {
        type: "FeatureCollection",
        features: dots.filter((dot) => dot.properties.time === time),
      };
    } else if (time >= 0.5 && time <= 0.624975) {
      json[4][time] = {
        type: "FeatureCollection",
        features: dots.filter((dot) => dot.properties.time === time),
      };
    } else if (time >= 0.625 && time <= 0.749975) {
      json[5][time] = {
        type: "FeatureCollection",
        features: dots.filter((dot) => dot.properties.time === time),
      };
    } else if (time >= 0.75 && time <= 0.874975) {
      json[6][time] = {
        type: "FeatureCollection",
        features: dots.filter((dot) => dot.properties.time === time),
      };
    } else if (time >= 0.875 && time <= 1) {
      json[7][time] = {
        type: "FeatureCollection",
        features: dots.filter((dot) => dot.properties.time === time),
      };
    }
  }

  return [json];
};

export const IRREGULAR_DEST_MAPPING = {
  "Reis West-Afrika": {
    id: "w",
    dest: ["Angola", "Bovenkust", "De Bocht"],
  },
  Driehoeksreis: {
    id: "d",
    dest: ["Angola", "Bovenkust", "De Bocht"],
  },
  "Reis Nederlandse cariben": {
    id: "c",
    dest: ["Spaanse koloniën"],
  },
  "Reis Spaanse cariben": {
    id: "c",
    dest: ["Spaanse koloniën"],
  },
  Europareis: {
    id: "e",
    dest: [
      "Golf van Biskaje",
      "Oostzee",
      "Portugal & Spanje",
      "Middellandse Zee",
    ],
  },
};

export function filterBestemming(bestemming, i, arr) {
  let mapping = "";
  if (IRREGULAR_DEST_MAPPING[bestemming.reis.type.naam]) {
    mapping = IRREGULAR_DEST_MAPPING[bestemming.reis.type.naam];
    for (let i = 0; i < mapping.dest.length; i++) {
      if (
        bestemming.bestemming.plaats ===
        mapping.dest[i] + " " + mapping.id + 2
      ) {
        return false;
      }
    }
  }

  return { bestemming, i };
}

export function adjustBestemming(bestemming, i, arr) {
  let mapping = "";
  let obj;
  if (IRREGULAR_DEST_MAPPING[bestemming.reis.type.naam]) {
    mapping = IRREGULAR_DEST_MAPPING[bestemming.reis.type.naam];
    for (let x = 0; x < mapping.dest.length; x++) {
      if (
        bestemming.bestemming.plaats ===
        mapping.dest[x] + " " + mapping.id + 1
      ) {
        obj = cloneDeep(bestemming);
        obj.vertrek = arr[i + 1].vertrek; // next bestemming vertrek
        obj.bestemming.plaats = mapping.dest[x];
        return obj;
      }
    }
  }
  return bestemming;
}
