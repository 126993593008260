import { useDispatch } from "../hooks/useReducer";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { rem } from "polished";
import Icon from "./Icons";
import fontParser from "../utils/fontParser";
import { StyledColumn, StyledValue } from "./CardBodyContent";
import { useTranslation } from "react-i18next";
import {
  formatDate,
  convertDateToAbsolute,
  calcDays,
  getTranslatedReisType,
  getTranslatedRole,
  getTranslatedDestination,
} from "../utils/helperFunctions";
import { StyledGoodsIcon, StyledEnslavedIcon } from "./OverviewListShipItem";

const StyledRow = styled.div`
  ${({ theme, $page }) =>
    `
    background-color: ${theme.color.key.white};
    margin: ${rem(16)} ${rem(16)} 0 ${rem(16)};
    display: flex;  
    flex-direction: column;
    ${$page === "overview" ? "cursor: pointer;" : ""}
    &:last-child {
      margin-bottom: ${rem(16)};
    }
  `}
`;

const Column = styled(StyledColumn)`
  ${({ theme }) => `
    padding: ${rem(4)} 0 0 0;
    margin-bottom: 0;
    display: flex; 
    align-items: start;
    flex-wrap: wrap;
    flex-direction: column;
`}
`;

const StyledLabel = styled.div`
  ${({ theme, $color }) => `
    color: ${theme.color.travel[$color].primary};
    font-weight: bold;
    margin-bottom: ${rem(4)};
    margin-left: ${rem(4)};
`}
`;

const StyledValueWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${rem(8)};
`;

const StyledWrapper = styled.div`
  margin-bottom: ${rem(12)};
  div {
    margin-bottom: ${rem(8)};
  }
`;

const StyledContentWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: ${rem(8)};
  &:hover {
    cursor: pointer;
  }
`;

const StyledVoyageData = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledRowPosition = styled.div`
  ${({ theme }) =>
    `
     color: ${theme.color.key.dark};
     display: flex;
     justify-content: center;
     align-items: center;
     font-weight: bold;
     align-self: center;
     margin-right: ${rem(4)};
     width: ${rem(16)};
    `}
`;

const StyledBody = styled.div`
  ${({ theme }) => `
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
`}
`;

const StyledMetaDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledMetaDataEnd = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
`;

const StyledMetaData = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMetaDataItem = styled.h4`
  ${({ theme }) =>
    `
      font-weight: normal;
`}
`;
const StyledIcon = styled(Icon)`
  ${({ theme }) =>
    `
  width: ${rem(13)};
  height: ${rem(6)};
  margin: 0 ${rem(4)};
  color: ${theme.color.humanTrafficking.dark};
`}
`;

const StyledName = styled.h4`
  text-decoration: underline;
  font-weight:normal;
  margin-left: ${rem(4)};
`;

const CardListItem = ({
  data,
  position,
  page = "map",
  children = null,
  last = false,
  lastBestemming = null,
  returnGoods = null,
  open,
  color,
  onClick,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleVoyageClick = (data) => {
    if (data.datum_aanvang_reis) {
      dispatch({
        type: "UPDATE_TIME",
        payload: convertDateToAbsolute(data.datum_aanvang_reis),
      });
      navigate(`/map/${data.id}`, { state: { voyage: data.id } });
    }
  };

  if (page === "map") {
    if (last) {
      return (
        <StyledRow $open={open} $page={page}>
          <StyledContentWrapper onClick={onClick}>
            <StyledRowPosition>{position}</StyledRowPosition>
            <StyledBody $page={page}>
              <StyledMetaDataWrapper>
                <StyledMetaData>
                  {getTranslatedReisType(
                    lastBestemming.reis.type.naam,
                    i18n.language
                  )}
                </StyledMetaData>
                <StyledName>
                  {getTranslatedDestination(
                    data.plaats_einde_reis?.plaats,
                    i18n.language
                  )}
                </StyledName>
              </StyledMetaDataWrapper>
              <StyledMetaDataEnd>{children}</StyledMetaDataEnd>
            </StyledBody>
          </StyledContentWrapper>
          {open && (
            <StyledVoyageData>
              {returnGoods !== null && returnGoods.length > 0 && (
                <Column $col={12}>
                  <StyledLabel $color={color}>
                    {t("mapDetailPanel.destinationCardLastJourneyGoods")}
                  </StyledLabel>
                  <StyledValue>
                    <StyledValueWrapper>
                      <StyledGoodsIcon id={"Goods"} />
                      {returnGoods
                        .map((goed) => {
                          return i18n.language === "nl"
                            ? goed.goederen_id.naam
                            : goed.goederen_id.naam_en;
                        })
                        .join(", ")}
                    </StyledValueWrapper>
                  </StyledValue>
                </Column>
              )}
              {data?.datum_einde_reis && lastBestemming?.vertrek && (
                <Column $col={12}>
                  <StyledWrapper>
                    <StyledLabel $color={color}>
                      {t("mapDetailPanel.destinationCardLastJourneyDuration")}
                    </StyledLabel>
                    <StyledValue>
                      {calcDays(lastBestemming.vertrek, data.datum_einde_reis)}{" "}
                      {t("mapDetailPanel.destinationCardLastJourneyDays")}
                    </StyledValue>
                  </StyledWrapper>
                </Column>
              )}
            </StyledVoyageData>
          )}
        </StyledRow>
      );
    } else {
      return (
        <StyledRow $open={open} $page={page}>
          <StyledContentWrapper onClick={onClick}>
            <StyledRowPosition>{position}</StyledRowPosition>
            <StyledBody $page={page}>
              <StyledMetaDataWrapper>
                <StyledMetaData>
                  {getTranslatedReisType(data.reis.type.naam, i18n.language)}
                </StyledMetaData>
                <StyledName>
                  {getTranslatedDestination(
                    data.bestemming.plaats,
                    i18n.language
                  )}
                </StyledName>
              </StyledMetaDataWrapper>
              <StyledMetaDataEnd>{children}</StyledMetaDataEnd>
            </StyledBody>
          </StyledContentWrapper>
          {open && (
            <StyledVoyageData>
              {data.tussenstops !== null && data.tussenstops !== "" && (
                <Column $col={12}>
                  <StyledWrapper>
                    <StyledLabel $color={color}>
                      {t("mapDetailPanel.destinationCardStopovers")}
                    </StyledLabel>
                    <StyledValue>{data.tussenstops}</StyledValue>
                  </StyledWrapper>
                </Column>
              )}
              {(data.mensen_ingekocht || data.goederen_ingekocht) && (
                <Column $col={12}>
                  {(data.mensen_ingekocht ||
                    data.goederen_ingekocht.length > 0) && (
                    <StyledLabel $color={color}>
                      {t("mapDetailPanel.destinationCardPurchased")}
                    </StyledLabel>
                  )}
                  {data.mensen_ingekocht && (
                    <StyledValue>
                      <StyledValueWrapper>
                        <StyledEnslavedIcon id={"Enslaved"} />
                        {data.mensen_ingekocht}
                        {t("mapDetailPanel.enslaved")}
                      </StyledValueWrapper>
                    </StyledValue>
                  )}
                  {data.goederen_ingekocht !== null &&
                    data.goederen_ingekocht.length > 0 && (
                      <StyledValue>
                        <StyledValueWrapper>
                          <StyledGoodsIcon id={"Goods"} />
                          {data.goederen_ingekocht
                            .map((goed) => {
                              return i18n.language === "nl"
                                ? goed.goederen_id.naam
                                : goed.goederen_id.naam_en;
                            })
                            .join(", ")}
                        </StyledValueWrapper>
                      </StyledValue>
                    )}
                </Column>
              )}
              {(data.mensen_verkocht || data.goederen_verkocht) && (
                <Column $col={12}>
                  {(data.mensen_verkocht ||
                    data.goederen_verkocht.length > 0) && (
                    <StyledLabel $color={color}>
                      {t("mapDetailPanel.destinationCardSold")}
                    </StyledLabel>
                  )}
                  {data.mensen_verkocht && (
                    <StyledValue>
                      <StyledValueWrapper>
                        <StyledEnslavedIcon id={"Enslaved"} />
                        {data.mensen_verkocht}
                        {t("mapDetailPanel.enslaved")}
                      </StyledValueWrapper>
                    </StyledValue>
                  )}
                  {data.goederen_verkocht !== null &&
                    data.goederen_verkocht.length > 0 && (
                      <StyledValue>
                        <StyledValueWrapper>
                          <StyledGoodsIcon id={"Goods"} />
                          {data.goederen_verkocht
                            .map((goed) => {
                              return i18n.language === "nl"
                                ? goed.goederen_id.naam
                                : goed.goederen_id.naam_en;
                            })
                            .join(", ")}
                        </StyledValueWrapper>
                      </StyledValue>
                    )}
                </Column>
              )}
              {data?.datum_einde_reis && lastBestemming?.vertrek && (
                <Column $col={12}>
                  <StyledWrapper>
                    <StyledLabel $color={color}>
                      {t("mapDetailPanel.destinationCardJourneyDuration")}
                    </StyledLabel>
                    <StyledValue>
                      {calcDays(lastBestemming.vertrek, data.datum_einde_reis)}{" "}
                      {t("mapDetailPanel.destinationCardLastJourneyDays")}
                    </StyledValue>
                  </StyledWrapper>
                </Column>
              )}
              {(data.aankomst || data.vertrek) && (
                <Column $col={12}>
                  <StyledWrapper>
                    <StyledLabel $color={color}>
                      {t("mapDetailPanel.destinationCardJourneyDuration")}
                    </StyledLabel>
                    <StyledValue>
                      {data.aankomst &&
                        formatDate(data.aankomst, i18n.language)}
                      {data.aankomst && data.vertrek && ` - `}
                      {data.vertrek && formatDate(data.vertrek, i18n.language)}
                    </StyledValue>
                  </StyledWrapper>
                </Column>
              )}
            </StyledVoyageData>
          )}
        </StyledRow>
      );
    }
  }

  if (page === "overview") {
    return (
      <StyledRow onClick={() => handleVoyageClick(data)} $page={page}>
        <StyledContentWrapper>
          <StyledRowPosition>{position}</StyledRowPosition>
          <StyledBody $page={page}>
            <StyledMetaDataWrapper>
              <StyledMetaData>
                <StyledMetaDataItem>
                  {formatDate(data.datum_aanvang_reis, i18n.language)}
                </StyledMetaDataItem>
                <StyledIcon id="ArrowRight" />
                <StyledMetaDataItem>
                  {formatDate(data.datum_einde_reis, i18n.language)}
                </StyledMetaDataItem>
              </StyledMetaData>
              {!!data?.voyage?.naam && (
                <StyledName>
                  {getTranslatedReisType(data.voyage.naam, i18n.language)}
                </StyledName>
              )}
            </StyledMetaDataWrapper>
            <StyledMetaDataEnd>
              {data.goederen_ingekocht !== null &&
                data.goederen_ingekocht === 0 &&
                data.goederen_verkocht !== null &&
                data.goederen_verkocht === 0 && (
                  <StyledGoodsIcon id={"Goods"} />
                )}
              {data.mensenhandel !== null &&
                data.aantal_mensen_gekocht > 0 &&
                data.aantal_mensen_verkocht !== null &&
                data.aantal_mensen_verkocht > 0 && (
                  <StyledEnslavedIcon id={"Enslaved"} />
                )}
              {children}
            </StyledMetaDataEnd>
          </StyledBody>
        </StyledContentWrapper>
      </StyledRow>
    );
  }
};

export default CardListItem;
