import styled from "styled-components";
import fontParser from "../utils/fontParser";
import { rem } from "polished";
import { useTranslation } from "react-i18next";
import CtaButton from "../components/CtaButton";
import LogoMcc from "../components/LogoMcc";
import HeroHome from "../assets/hero-home.png";
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useEffect } from "react";


const StyledPanel = styled.section`
  ${({ theme }) => `
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    @media only screen and (min-width: ${theme.breakPoint}px) {
        position: fixed;
        height: 100vh;
        bottom: 0;
        width: calc(100% - ${rem(167)});
        left: ${rem(167)};
        transition: all ${theme.transition.timing} ${theme.transition.function};
    }
    `}
`;

const StyledHeader = styled.header`
  ${({ theme }) => `
display: flex;
flex-direction: column;
width: 100%;
padding: ${rem(13)} ${rem(16)} ${rem(14)} ${rem(16)};
border-bottom: solid ${rem(1)} ${theme.color.globalMenu.dark};

@media only screen and (min-width: ${theme.breakPoint}px) {
  margin-top: unset;
  padding: ${rem(24)} ${rem(96)} ${rem(24)} ${rem(96)};
}
`}
`;

const StyledContent = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: ${rem(40)} ${rem(24)};

    @media only screen and (min-width: ${theme.breakPoint}px) {
      margin-top: unset;
      padding: ${rem(24)} ${rem(96)};
    }
`}
`;

const StyledHeadLine = styled.h3`
  ${({ theme }) => `
    ${fontParser("headline")};
    margin-bottom: ${rem(8)};
    letter-spacing: 0.01em;
    text-transform: uppercase;

    @media only screen and (min-width: ${theme.breakPoint}px) {
      margin-bottom: ${rem(16)};
    }
`}
`;

const StyledTitle = styled.h1`
  ${({ theme }) => `
   ${fontParser("largeTitle")};
    font-family: "Open Sans";
    margin-bottom: ${rem(8)};

    @media only screen and (min-width: ${theme.breakPoint}px) {
      margin-bottom: ${rem(24)};
    }
`}
`;

const StyledText = styled.p`
  ${({ theme }) => `
  
    margin-bottom: ${rem(16)};
    color: ${theme.color.key.default};
    font-family: "Open Sans";
    font-style: normal;


    @media only screen and (min-width: ${theme.breakPoint}px) {
      margin-bottom: ${rem(32)};
      font-size: ${rem(40)};
      line-height: ${rem(48)};
    }
`}
`;

const StyledHomeFooter = styled.div`
   ${({ theme }) => `
  display: flex;
  flex-direction: column;
  position:absolute;
  bottom:${rem(55)};
   @media only screen and (min-width: ${theme.breakPoint}px) {
    position:unset;
   }
   `}
`;

const StyledCtaButton = styled(CtaButton)`
  ${({ theme }) => `
  font-size: ${rem(12)};

  @media only screen and (min-width: ${theme.breakPoint}px) {
    font-size: ${rem(16)};
  }
  `}
`;

const StyledHero = styled.img`
  ${({ theme }) => `
    object-fit: cover;
    width: 100%;
}
`}
`;

const Home = () => {
  const { t } = useTranslation();
  const { trackPageView, trackEvent } = useMatomo()

  // Track page view
  useEffect(() => {
      trackPageView({
              documentTitle: 'Homepage', // optional
          }
      );
  }, [])

  return (
    <StyledPanel>
      <StyledHeader>
        <LogoMcc />
      </StyledHeader>
      <StyledContent>
        <StyledHeadLine>{t("home.subtitle")}</StyledHeadLine>
        <StyledTitle>{t("home.title")}</StyledTitle>
        <StyledText>{t("home.content")}</StyledText>
        <StyledCtaButton direction="right" to="/map">
          {t("home.link")}
        </StyledCtaButton>
      </StyledContent>
      <StyledHomeFooter>
        {<StyledHero src={HeroHome} alt="Zeeuws Archief" />}
      </StyledHomeFooter>
    </StyledPanel>
  );
};

export default Home;
