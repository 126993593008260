import styled from "styled-components";
import { rem } from "polished";
import fontParser from "../utils/fontParser";
import Icon from "./Icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getTranslatedShipType } from "../utils/helperFunctions";

export const StyledListItemContainer = styled.li`
  ${({ theme }) =>
    `
    list-style: none;
    flex: 0 0 auto;
    width: 100%;
    padding: 0 ${rem(12)};
    margin-bottom: ${rem(16)};

    @media only screen and (min-width: 720px) {
      margin-bottom: ${rem(24)};
      width: 50%;
  }

    @media only screen and (min-width: ${theme.breakPoint}px) {
        margin-bottom: ${rem(24)};
        width: 25%;
    }
`}
`;

export const StyledListItem = styled(Link)`
  ${({ theme, $isDetailOpen, $isSelected }) =>
    `
    width: 100%;
    box-sizing: border-box;
    display: block;
    text-decoration: none;
    color: ${theme.color.key.dark};
    cursor: pointer;
    position: relative;

    ${
      $isDetailOpen
        ? `
        &:after{
          content: '';
          background-color: ${theme.color.key.white};
          opacity: 0.4;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          position: absolute;
          z-index: 1;
        }
      
        `
        : ``
    }

   ${
     $isSelected
       ? `
        box-shadow: 0 0 0 2px ${theme.color.key.primary};
        `
       : ``
   }
`}
`;

export const StyledItemHeader = styled.div`
  ${({ theme }) =>
    `
    display: flex;
    align-items: baseline;
    background-color: ${theme.color.key.white};
    height: ${rem(36)};
    ${fontParser("cardType")};
`}
`;

export const StyledItemHeaderTitle = styled.h3`
  ${({ theme }) =>
    `
  ${fontParser("caption2")};
  padding: 0 ${rem(8)} 0 ${rem(16)};
  text-transform: uppercase;
`}
`;

export const StyledItemNumberHightlighted = styled.div`
  ${({ theme }) => `
    color: ${theme.color.globalMenu.primary};
    font-weight: bold;
    height: 100%;
    display: flex;
    align-items: center;
    font-size:${rem(16)};
`}
`;

export const StyledItemBody = styled.div`
  ${({ theme }) =>
    `
    background-color: ${theme.color.key.white};
    position: relative;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    padding-left: ${rem(24)};
    height: ${rem(140)};
    @media only screen and (min-width: ${theme.breakPoint}px) {
        padding: 0 ${rem(16)};
        height: ${rem(144)};
    }
`}
`;

export const StyledName = styled.h4`
  ${({ theme }) => `
    ${fontParser("cardName")};
    z-index: 1;
    margin-top:-10px;
`}
`;

export const StyledIcon = styled(Icon)`
  ${({ theme }) => `
    height: ${rem(80)};
    position: absolute;
    right: 0;
    bottom: 0;
    color:  ${theme.color.key.primary};
    margin: ${rem(8)} ${rem(16)};
    `}
`;

const StyledLegenda = styled.div`
  ${({ theme }) => `
  display: flex;
  margin-top:  ${rem(8)};
  margin-bottom: ${rem(80)};
`}
`;

export const StyledGoodsIcon = styled(Icon)`
  ${({ theme }) => `
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: ${rem(8)};
`}
`;

export const StyledEnslavedIcon = styled(Icon)`
  ${({ theme }) => `
  min-width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: ${rem(8)};
`}
`;

const StyledSeizedIcon = styled(Icon)`
  ${({ theme }) => `
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: ${rem(8)};
`}
`;

const OverviewListItem = ({ item, isDetailOpen, isSelected }) => {
  const { t, i18n } = useTranslation();

  return (
    <StyledListItemContainer>
      <StyledListItem
        to={item.id}
        $isDetailOpen={isDetailOpen}
        $isSelected={isSelected}
      >
        <StyledItemHeader>
          <StyledItemHeaderTitle>
            {getTranslatedShipType(item.scheepstype.naam, i18n.language)}
          </StyledItemHeaderTitle>

          <StyledItemNumberHightlighted>
            {item.aantal_reizen_mcc ? item.aantal_reizen_mcc : 0}{" "}
            {item.aantal_reizen_mcc === 1 && t("ships.travelLabelSingular")}
            {item.aantal_reizen_mcc > 1 && t("ships.travelLabel")}
          </StyledItemNumberHightlighted>
        </StyledItemHeader>
        <StyledItemBody>
          <StyledName>{item.naam}</StyledName>
          <StyledIcon id={item.scheepstype.naam} />
          <StyledLegenda>
            {item.icon_mensenhandel && <StyledEnslavedIcon id={"Enslaved"} />}
            {item.icon_goederen && <StyledGoodsIcon id={"Goods"} />}
            {item.icon_onvoltooid && <StyledSeizedIcon id={"Seized"} />}
          </StyledLegenda>
        </StyledItemBody>
      </StyledListItem>
    </StyledListItemContainer>
  );
};

export default OverviewListItem;
