import styled from "styled-components";
import { rem } from "polished";
import Cards from "./Cards";
import CardVoyageListItem from "./CardVoyageListItem";
import CardBodyContent from "./CardBodyContent";
import { StyledColumn, StyledLabel, StyledValue } from "./CardBodyContent";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Icon from "../components/Icons";
import {getTranslatedArchiveTitle} from "../utils/helperFunctions";

const StyledDetailBody = styled.div`
  ${({ theme }) => `
    height: auto;
    color: ${theme.color.key.dark};
    background-color: ${theme.color.key.white};
    display: flex;
    flex-direction: column;
    padding: ${rem(16)};
    margin: 0 ${rem(16)} ${rem(16)} ${rem(16)};

    @media only screen and (min-width: ${theme.breakPoint}px) {
      padding: 0;
   }
`}
`;

const StyledCard = styled(Cards)`
  ${({ theme }) => `
// margin-bottom: ${rem(16)};
@media only screen and (min-width: ${theme.breakPoint}px) {
  border-top: 0;
}
`}
`;

const StyledLink = styled(Link)`
  ${({ theme }) => `
  color: ${theme.color.key.dark};
  display: flex;
  align-items: center;
  margin-bottom: ${rem(4)};

  svg {
    height: ${rem(12)};
    width: ${rem(12)};
    flex-shrink: 0;
  }
`}
`;

const StyledLinkContent = styled.span`
  margin-left: ${rem(4)};
  flex-shrink: 0;
`;

const DetailBody = ({ data, voyageData, archiveItems, blogItems }) => {
  const { t, i18n } = useTranslation();
  const languageID = i18n.language === "nl" ? "nl-NL" : "en-US";
  const translations = data.translations.find(
    (translation) => translation.languages_code.code === languageID
  );

  return (
    <StyledDetailBody>
      <StyledCard title={t("shipDetail.buildLabel")}>
        <CardBodyContent>
          {!!data.plaats_van_bouw && (
            <StyledColumn>
              <StyledLabel>{t("shipDetail.buildPlace")}</StyledLabel>
              <StyledValue>{data.plaats_van_bouw}</StyledValue>
            </StyledColumn>
          )}
          {!!data.jaar_voltooiing_bouw && (
            <StyledColumn>
              <StyledLabel>{t("shipDetail.buildYear")}</StyledLabel>
              <StyledValue>{data.jaar_voltooiing_bouw}</StyledValue>
            </StyledColumn>
          )}
          {translations && translations?.scheepstimmerman_vertaling && (
            <StyledColumn>
              <StyledLabel>{t("shipDetail.buildBuilder")}</StyledLabel>
              <StyledValue>
                {translations.scheepstimmerman_vertaling}
              </StyledValue>
            </StyledColumn>
          )}
          {translations && translations?.herkomst_schip_vertaling && (
            <StyledColumn>
              <StyledLabel>{t("shipDetail.buildAncestor")}</StyledLabel>
              <StyledValue>{translations.herkomst_schip_vertaling}</StyledValue>
            </StyledColumn>
          )}
        </CardBodyContent>
      </StyledCard>
      {voyageData && (
        <StyledCard title={t("shipDetail.voyageLabel")}>
          <CardBodyContent>
            {data.aantal_reizen_mcc && (
              <StyledColumn>
                <StyledLabel>{t("shipDetail.buildVoyages")}</StyledLabel>
                <StyledValue>{data.aantal_reizen_mcc}</StyledValue>
              </StyledColumn>
            )}
            {data.aantal_reizen_verhuurd && (
              <StyledColumn>
                <StyledLabel>{t("shipDetail.buildVoyagesHired")}</StyledLabel>
                <StyledValue>{data.aantal_reizen_verhuurd}</StyledValue>
              </StyledColumn>
            )}
            {data.aantal_reizen_geannuleerd && (
              <StyledColumn>
                <StyledLabel>
                  {t("shipDetail.buildVoyagesCancelled")}
                </StyledLabel>
                <StyledValue>{data.aantal_reizen_geannuleerd}</StyledValue>
              </StyledColumn>
            )}
          </CardBodyContent>
          {voyageData.map((element, id) => {
            return (
              <CardVoyageListItem
                key={id}
                data={element}
                position={id + 1}
                page={"overview"}
              />
            );
          })}
        </StyledCard>
      )}
      {archiveItems && archiveItems.length > 0 && (
        <StyledCard
          title={t("shipDetail.archiveTitle")}
          icon={"Archive"}
          category=""
        >
          <CardBodyContent>
            {archiveItems.map((item) => {
              return (
                <StyledColumn $col={12}>
                  <StyledValue>
                    <StyledLink
                      to={item.link !== null ? item.link : ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon id="Link" />
                      <StyledLinkContent>{getTranslatedArchiveTitle(item.title, i18n.language)}</StyledLinkContent>
                    </StyledLink>
                  </StyledValue>
                </StyledColumn>
              );
            })}
          </CardBodyContent>
        </StyledCard>
      )}
      {blogItems && blogItems.length > 0 && (
        <StyledCard title={t("shipDetail.blogTitle")} icon={"Blog"} category="">
          <CardBodyContent>
            {blogItems.map((item) => {
              return (
                <StyledColumn $col={12}>
                  <StyledValue>
                    <StyledLink to={`/blog/${item.translations[0].slug}`}>
                      <Icon id="Link" />
                      <StyledLinkContent>
                        {item.translations[0].title}
                      </StyledLinkContent>
                    </StyledLink>
                  </StyledValue>
                </StyledColumn>
              );
            })}
          </CardBodyContent>
        </StyledCard>
      )}
    </StyledDetailBody>
  );
};

export default DetailBody;
